import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AuthenticationLayout from "../Components/layout/AuthenticationLayout";

const StyledContainer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: "Nunito Sans", sans-serif;

  margin-top: 60px;
  padding: 60px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 5px 10px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: green;
    cursor: unset;
  }
`;

export default function PaymentSuccessful() {
  return (
    <AuthenticationLayout maxWidth="700px">
      <StyledContainer>
        <h3>
          Your Payment was Successful &nbsp;
          <StyledIcon
            icon={faCheckSquare}
            color="green"
            className="custom-icon"
          />
        </h3>

        <br />

        <p>Thank you for your subscribing with EliteCloud.</p>
        <p>
          Your transaction has completed and we are sending you an email
          containing the receipt for your purchase.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <a
            href={"https://apps.apple.com/nz/app/elitecloud/id1508242548"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={"app-store-icon.svg"} width="100px" alt="App Store" />
          </a>
          <a
            href={
              "https://play.google.com/store/apps/details?id=nz.co.aap.elitecontrol"
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"google-play-icon.svg"}
              width="100px"
              alt="Google Store"
            />
          </a>
        </div>
        <br />
        <div>
          <span style={{ fontSize: "10px" }}>
            Follow the icons above for downloading
          </span>
        </div>
      </StyledContainer>
    </AuthenticationLayout>
  );
}
