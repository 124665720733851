import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Footer from "../Shared/Footer";
import Logo from "../Img/Logo.png";

export default function Register() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  let history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({});
  const [userinfo] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setconfirmShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmShown(confirmShown ? false : true);
  };

  const onSubmit = (data) => {
    console.log("data", data);
    console.log("userinfo.firstname", data.firstName);
    const registerdata = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      // username: data.username,
      password: data.password,
      is_agreed: data.isAgreed,
    };
    console.log("registerdata", registerdata);
    var formBody = [];
    for (var property in registerdata) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(registerdata[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log("formbody", formBody);
    const headers = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post("https://auth.api.elitecloud.co.nz/user/join/", formBody, headers)
      .then((response) => {
        //console.log("registerresponse",response);
        history.push("/thankyou");
      })
      .catch((error) => {
        if (error.response.data.status_code === 409) {
          toast.error("Duplicated  email address");
        }
        if (error.response.data.status_code === 417) {
          toast.error(
            "Password should not contain user's account name or parts of the user's full name"
          );
        }
        if (error.response.data.status_code === 503) {
          toast.error(
            "Failed to send account activation email (Account not created)"
          );
        }
        if (error.response.data.status_code === 400) {
          toast.error("Please check your Input fields");
        }
        //toast.error(error.response.data.message);
        console.log("log error", error);
      });
  };

  return (
    <div>
      <Container fluid>
        <div className="home">
          <Row>
            <ToastContainer
              position="top-right"
              autoClose={10000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Col xs={12}>
              <div className="registerHead">
                <pre>{JSON.stringify(userinfo)}</pre>
                <Link
                  to={{ pathname: "https://elitecloud.co.nz/" }}
                  target="_blank"
                >
                  <img src={Logo} alt="logo" style={{ width: "80px" }} />
                </Link>
                <h2>Sign up</h2>
                <small>
                  Have an account already?&nbsp;<Link to="/login">Sign In</Link>{" "}
                  here
                </small>
              </div>
              <Col
                xs={12}
                md={{ span: 6, offset: 3 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  data-aos="zoom-in"
                >
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="registerSec"
                  >
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            {...register("firstName", {
                              required: "First Name is required",
                              maxLength: {
                                value: 64,
                                message: "maximum 64 characters only!",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors.firstName?.message}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            {...register("lastName", {
                              required: "Last Name is required",
                              maxLength: {
                                value: 64,
                                message: "maximum 64 characters only!",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors.lastName?.message}
                          </p>
                        </Form.Group>
                      </Col>
                      <br />
                      <Col xs={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: /^[a-zA-Z0-9-_.-]+@+[a-zA-Z0-9]+.+[A-z]/,
                                message: "Invalid email!",
                              },
                            })}
                          />
                          <p className="text-danger">{errors.email?.message}</p>
                        </Form.Group>
                      </Col>
                      <br />
                      {/* <Col xs={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            placeholder="Username"
                            {...register("username", {
                              required: "Username is required",
                              maxLength: {
                                value: 255,
                                message: "maximum 255 characters only!",
                              },
                              pattern: {
                                value: /^\S*$/,
                                message: "Empty spaces are not allowed.",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors.username?.message}
                          </p>
                        </Form.Group>
                      </Col>
                      <br /> */}

                      <Col xs={12}>
                        <div className="password_security">
                          <label>Password</label>
                          <InputGroup>
                            <input
                              type={passwordShown ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                              autoComplete="off"
                              className=" form-control border-right-0 border"
                              {...register("password", {
                                required: "You must specify a password",
                                minLength: {
                                  value: 8,
                                  message: "Password is too short",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                  message:
                                    "Password must be 8-16 characters, include one uppercase letter, lowercase letter, and a number",
                                },
                              })}
                            />
                            <span className="input-group-append password__secure">
                              <span
                                className="password__secureleft"
                                onClick={togglePasswordVisiblity}
                              >
                                {passwordShown ? (
                                  <FontAwesomeIcon icon={faEye} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                              </span>
                            </span>
                          </InputGroup>
                          <p className="text-danger">
                            {errors.password?.message}
                          </p>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="password_security">
                          <label>Confirm Password</label>
                          <InputGroup>
                            <input
                              type={confirmShown ? "text" : "password"}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              autoComplete="off"
                              className=" form-control border-right-0 border"
                              {...register("confirmPassword", {
                                required: "Please confirm password!",
                                validate: {
                                  matchesPreviousPassword: (value) => {
                                    const { password } = getValues();
                                    return (
                                      password === value ||
                                      "Passwords should match!"
                                    );
                                  },
                                },
                              })}
                            />
                            <span className="input-group-append password__secure">
                              <span
                                className="password__secureleft  "
                                onClick={toggleConfirmPasswordVisiblity}
                              >
                                {confirmShown ? (
                                  <FontAwesomeIcon icon={faEye} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                              </span>
                            </span>
                          </InputGroup>

                          <p className="text-danger">
                            {errors.confirmPassword?.message}
                          </p>
                        </div>
                      </Col>
                      <br />
                      <Col xs={12}>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="isAgreed"
                            value="true"
                            {...register("isAgreed", {
                              required: "Please tick 'I agree' to continue",
                              maxLength: 20,
                            })}
                          />
                          <label >
                            
                              I agree with <Link
                              to={{
                                pathname:
                                  "https://account.elitecloud.co.nz/terms",
                              }}
                              target="_blank"
                            >terms and conditions </Link> | <Link
                            to={{
                              pathname:
                                "https://account.elitecloud.co.nz/privacy",
                            }}
                            target="_blank"
                          >Privacy policy</Link> |<Link
                          to={{
                            pathname:
                              "https://account.elitecloud.co.nz/cookiepolicy",
                          }}
                          target="_blank"
                        > Cookie policy
                           </Link> 
                          </label>
                        </div>
                      </Col>
                      <p className="text-danger">{errors.isAgreed?.message}</p>
                    </Row>
                    <br />
                    <Button variant="primary" type="submit">
                      Sign Up
                    </Button>
                  </Form>
                </Col>
              </Col>
            </Col>
          </Row>
        </div>
        <Footer />
      </Container>
    </div>
  );
}
