import React from "react";
import styled from "styled-components";
import ContentWithFooter from "./ContentWithFooter";

const StyledContainer = styled.div`
  margin-top: 60px;
  font-family: "Nunito Sans", sans-serif;
  text-align: center;

  h2 {
    font-size: 50px;
    font-weight: bolder;
    padding-top: 10px;
  }

  small {
    font-size: 15px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
  }

  div.brand-logo {
    color: #34528b;
    font-weight: 200;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span.bold {
    font-weight: 500;
  }

  img.elitecloud-logo {
    width: 80px;
  }
`;

export default function AuthenticationLayout({
  maxWidth,
  title,
  redirectLink,
  children,
}) {
  return (
    <ContentWithFooter maxwidth={maxWidth}>
      <StyledContainer>
        <div className="brand-logo">
          <a href="https://elitecloud.co.nz" target="_blank" rel="noreferrer">
            <img className="elitecloud-logo" src={"logo.png"} alt="logo" />
          </a>
          <span>&nbsp;Elite</span>
          <span className="bold">Cloud</span>
        </div>
        <br />
        {title && <h2>{title}</h2>}
        {redirectLink && redirectLink}
      </StyledContainer>

      {children}
    </ContentWithFooter>
  );
}
