import React,{Component} from 'react';
import '../App.css';
import {Container, Row, Col,Navbar } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faFacebook,faLinkedin,faYoutube,faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

export default class Footer extends Component{
    

render(){
    return(

<div>
    <Container>
      <Row className="footerSec">
        {/* <Col><img className="logo" src={logo}/></Col>
        <Col>
          <ListGroup>
            <ListGroup.Item className="Fterbld">Product</ListGroup.Item>
            <ListGroup.Item>Features</ListGroup.Item>
            <ListGroup.Item>Integration</ListGroup.Item>
            <ListGroup.Item>Documnentation</ListGroup.Item>
          <ListGroup.Item>Pricing</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
          <ListGroup.Item  className="Fterbld">About</ListGroup.Item>
          <ListGroup.Item>Our Story</ListGroup.Item>
          <ListGroup.Item>Company</ListGroup.Item>
          <ListGroup.Item>Our Team</ListGroup.Item>
          <ListGroup.Item>Work With Us</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item  className="Fterbld">Resources</ListGroup.Item>
            <ListGroup.Item>Help Center</ListGroup.Item>
            <ListGroup.Item>Developer API</ListGroup.Item>
            <ListGroup.Item>Our Blog</ListGroup.Item>
            <ListGroup.Item>Status</ListGroup.Item>
            <ListGroup.Item>Sitemap</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
          <ListGroup.Item  className="Fterbld">Contact</ListGroup.Item>
          <ListGroup.Item>Advertising</ListGroup.Item>
          <ListGroup.Item>Press</ListGroup.Item>
          <ListGroup.Item>Partners</ListGroup.Item>
          <ListGroup.Item>Email</ListGroup.Item>
          </ListGroup>
      </Col> */}
    </Row><hr/>
    <Row className="socialBar">
      <Col xs={12}><p className="cpyrght">©2021 Arrowhead Alarm Products Limited.All right reserved</p></Col>
      <Col></Col>
        {/* <Col>
          <ListGroup horizontal>
          <ListGroup.Item> <FontAwesomeIcon icon={faFacebook} /></ListGroup.Item>
          <ListGroup.Item><FontAwesomeIcon icon={faLinkedin} /></ListGroup.Item>
          <ListGroup.Item><FontAwesomeIcon icon={faInstagramSquare} /></ListGroup.Item>
          <ListGroup.Item><FontAwesomeIcon icon={faYoutube} /></ListGroup.Item>
          </ListGroup>
        </Col> */}
    </Row>
    </Container>

  
<Navbar bg="light"sticky="bottom">
 <Navbar.Brand href="#home"></Navbar.Brand>
</Navbar>
  
</div>

    );
};
}