import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Col, Form, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../Shared/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ConfirmPassword(props) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  let history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setconfirmShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmShown(confirmShown ? false : true);
  };

  const queryParams = new URLSearchParams(window.location.search);
  let token = queryParams.get("token");
  const onSubmit = (data) => {
    if (token) {
      console.log("param-token", token);
      const resetdata = {
        password: data.password,
      };
      console.log("resetdata", resetdata);
      var formBody = [];
      for (var property in resetdata) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(resetdata[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log("formbody", formBody);
      const headers = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      };
      axios
        .post(
          "https://auth.api.elitecloud.co.nz/user/password/reset/?token=" +
            token,
          formBody,
          headers
        )
        .then((response) => {
          //console.log("resetresponse",response);
          history.push("/");
        })
        .catch((error) => {
          toast.error(error);
          console.log("log error", error);
        });
    } else {
      history.push("/");
    }
  };

  return (
    <Container fluid>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <Col xs={12}>
        <div className="loginHead">
          <h2>Confirm Password</h2>
        </div>
        <Col md={{ span: 6, offset: 3 }}>
          <Col className="loginSec">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="password_security">
                <div className="password_security">
                  <label>Password</label>

                  <InputGroup>
                    <span className="input-group-append password__secure">
                      <span className="password__secureright ">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="password"
                      autoComplete="off"
                      className=" form-control border-right-0 border"
                      {...register("password", {
                        required: "You must specify a password",
                        minLength: {
                          value: 8,
                          message: "Too short",
                        },
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                          message: "Weak Password",
                        },
                      })}
                    />
                    <span className="input-group-append password__secure">
                      <span
                        className="password__secureleft"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </span>
                    </span>
                  </InputGroup>
                  <p className="text-danger">{errors.password?.message}</p>
                </div>
              </div>
              <div className="password_security">
                <div className="password_security">
                  <label>Confirm Password</label>
                  <InputGroup>
                    <span className="input-group-append password__secure">
                      <span className="password__secureright ">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </span>
                    <input
                      type={confirmShown ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      autoComplete="off"
                      className=" form-control border-right-0 border"
                      {...register("confirmPassword", {
                        required: "Please confirm password!",
                        validate: {
                          matchesPreviousPassword: (value) => {
                            const { password } = getValues();
                            return (
                              password === value || "Passwords should match!"
                            );
                          },
                        },
                      })}
                    />
                    <span className="input-group-append password__secure">
                      <span
                        className="password__secureleft  "
                        onClick={toggleConfirmPasswordVisiblity}
                      >
                        {confirmShown ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </span>
                    </span>
                  </InputGroup>

                  <p className="text-danger">
                    {errors.confirmPassword?.message}
                  </p>
                </div>
              </div>
              <Button variant="primary" className="loginSubmit" type="submit">
                Confirm
              </Button>
            </Form>
          </Col>
        </Col>
      </Col>
      <Footer />
    </Container>
  );
}
