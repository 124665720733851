import React from "react";

import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <p>
          <Link to="/privacy">Privacy</Link>&nbsp;&nbsp;|&nbsp;
          <Link to="/terms">Terms & Conditions</Link>&nbsp;&nbsp;|&nbsp;
          <Link to="/cookiepolicy">Cookie Preferences</Link>
        </p>
      </div>
    </>
  );
};
export default Footer;
