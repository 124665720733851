import React from 'react'

const ReadonlyRow = ({contact,handleEditClick}) => {
  return (
    <tr>
    <td>{contact.uuid}</td>
  <td>{contact.plan.name}</td>
  <td ><button type="button" onClick={(event)=>{handleEditClick(event,contact)}} >handleEditClick</button></td>
  </tr>
  )
}

export default ReadonlyRow