import React, { Component } from "react";
import { Container, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
//import NavbarSec from '../Shared/NavbarSec';
import Footer from "../Shared/Footer";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    const data = {
      email: this.state.username.toLowerCase(),
    };
    console.log("data", data);
    event.preventDefault();

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const headers = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };

    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/password/find/",
        formBody,
        headers
      )
      .then((response) => {
        console.log("response", response);
        toast.info("Reset link has been sent to email ");
      })
      .catch((error) => {
        //console.log("error", error)
        toast.error(error.response.data.message);
      });
  }
  render() {
    return (
      <Container fluid>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="resetHead">
          <h2>Reset Password</h2>
          <small style={{ color: "#333" }}>
            or, return to <Link to="/login"> Sign In</Link>
          </small>
        </div>
        <br />
        <Col xs={12} md={{ span: 4, offset: 4 }}>
          <Col
            className="resetSec"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  placeholder=""
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Button variant="primary" className="loginSubmit" type="submit">
                Reset
              </Button>
            </Form>
          </Col>
        </Col>

        <Footer />
      </Container>
    );
  }
}
