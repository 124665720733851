import React, {  useEffect, useState } from "react";
import { Container,Table,Modal,Row,Col } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Sitelist = () => {
  const [details, setDetails] = useState([]);
const [lgShow, setLgShow] = useState(false);
const[sites,setSites]=useState(false);


  useEffect( () => {
    try {
      let token = Cookies.get("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  axios
        .get(`https://panel.api.elitecloud.co.nz/subscription/`, config)
        .then((response) => {
          let data = response.data.results;
          console.log("data", data);
          setDetails(data);
          data.map((data) =>Cookies.set('uuidlist',data.uuid)
         );
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  
  const handleEditClick=(event,detail)=>{
   event.preventDefault();
   
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        "https://panel.api.elitecloud.co.nz/subscription/" +
        detail.uuid +
          "/site/",
        config
      )
      .then((response) => {
        //console.log("responsesubscription", response.data.results);
       setSites(response.data.results)
      })
      .catch((error) => {
       // console.log("error", error);
      });
    
  }
  return (
    <Container>
    <Row><Col><div className="ProfileAccount">
              <h2> Sites</h2>
            </div></Col></Row>
   
<Table striped bordered hover size="sm">
  <thead>
    <tr>
      
      <th>Plan Name</th>
     
      <th>Active Site</th>
      <th>Site</th>
    </tr>
  </thead>
  <tbody>
  {details.map((detail)=><>
    <tr>
     
      <td>{detail.plan.name}</td>
      <td>{detail.is_active ? <>Yes</>:<>No</>}</td>
      <td  onClick={(event)=>{handleEditClick(event,detail)}} style={{color:'#4980be',cursor:'pointer'}}><p onClick={() => setLgShow(true)} style={{fontSize:'12px'}}>View Site</p></td>
    </tr>
    </>)}
  
  </tbody>
</Table>


      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>  {sites? <>{sites.map((site)=><>
          <Table striped bordered hover>
  <thead>
    <tr>
     
      <th>Site Details</th>
      <th>Site </th>
      <th>Mac Address</th>
      <th>Serial No</th>
      <th>Connection Healthy</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{site.name}<br/>{site.address.address_line_1},{site.address.address_line_2}<br/>{site.address.city}
      <br/>{site.address.country}</td>
      <td><img src={site.profile_img_url} alt="siteimg" width="40px" style={{borderRadius:'30px'}}/></td>
      <td>{site.panel.mac_address}</td>
      <td>{site.panel.serial_no}</td>
      <td>{site.panel.is_connection_healthy?<>Yes</>:<>No</>}</td>
    </tr>
    
  </tbody>
</Table>
</>)}</>:<><p>No Sites</p></>}</Modal.Body>
      </Modal>
     
    
    </Container>
  );
};

export default Sitelist;
