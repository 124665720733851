import { createContext, useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
//import jwt_decode from "jwt-decode";
//import { faWindows } from "@fortawesome/free-brands-svg-icons";
import TermsModal from "../Components/TermsModal";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [count, setCount] = useState();
  const isAuthenticated = Cookies.get("access_token");
  const exptime = Cookies.get("exp_time");

  let history = useHistory();

  const logoutUser = useCallback(() => {
    Cookies.remove("access_token", { path: "/", domain: ".elitecloud.co.nz" });
    Cookies.remove("exp_time", { path: "/", domain: ".elitecloud.co.nz" });
    history.push("/login");
  }, [history]);

  const intervalRef = useRef();

  const getToken = useCallback(() => {
    const data = {
      access_token: Cookies.get("access_token"),
      refresh_token: Cookies.get("refresh_token"),
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let token = Cookies.get("access_token");
    const headers = {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    };

    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/token/refresh/",
        formBody,
        headers
      )
      .then((response) => {
        // alert("token is updated")

        if (response.status === 200) {
          Cookies.set("exp_time", response.data.payload.expire_at, {
            domain: ".elitecloud.co.nz",
          });
          Cookies.set("access_token", response.data.payload.access_token, {
            domain: ".elitecloud.co.nz",
          });
          Cookies.set("refresh_token", response.data.payload.refresh_token, {
            domain: ".elitecloud.co.nz",
          });
        }
      })
      .catch((error) => {
        if (error.status_code === 401) {
          console.log("jwt error", error);
          history.push("/login");
        }
      });
  }, [history]);

  const getUserSubscribtion = useCallback(() => {
    const token = Cookies.get("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // console.log("config",config)
    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)
      .then((res) => {
        setCount(res.data.count);
        if (res.data.count === 0) {
          history.push("/subscription/plans");
        } else {
          history.push("/site");
        }
      }, [])
      .catch((error) => {
        console.log("error", error);
      });
  }, [history]);

  useEffect(() => {
    // Cookies.set("isreloaded", true);

    if (isAuthenticated && exptime) {
      if (count === 0) {
        history.push("/subscription/plans");
      } else {
        if (Cookies.get("isreloaded")) {
          if (window.location.pathname === "/") {
            history.push("/subscription/plans");
          } else {
            //window.location.reload()
            //alert(window.location.pathname)
            history.push(window.location.pathname);
          }
        } else {
          history.push("/subscription/plans");
        }
      }
    }

    /* getTerms(); */
    const interval = setInterval(() => getToken(), 1000 * 60 * 4);
    intervalRef.current = interval;
    return () => clearInterval(interval);
  }, [
    getToken,
    getUserSubscribtion,
    logoutUser,
    count,
    exptime,
    history,
    isAuthenticated,
  ]);

  let loginUser = async (e) => {
    e.preventDefault();
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const headers = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post("https://auth.api.elitecloud.co.nz/user/login/", formBody, headers)
      .then((response) => {
        console.log("Reponse", response);

        switch (parseInt(response.status / 100)) {
          case 2:
            Cookies.set("access_token", response.data.payload.access_token, {
              domain: ".elitecloud.co.nz",
            });
            Cookies.set("refresh_token", response.data.payload.refresh_token, {
              domain: ".elitecloud.co.nz",
            });
            Cookies.set("exp_time", response.data.payload.expire_at, {
              domain: ".elitecloud.co.nz",
            });
            toast.info("Login Successful");
            break;

          case 5:
            toast.error("Server Maintaince, Try again later");
            break;
          default:
            break;
        }
        getUserSubscribtion();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status_code === 451) {
            setShow(true);
            Cookies.set(
              "access_token",
              error.response.data.payload.access_token
            );
            Cookies.set(
              "refresh_token",
              error.response.data.payload.refresh_token
            );
            Cookies.set("exp_time", error.response.data.payload.expire_at);
            console.log("response", error);
          }
          if (error.response.data.status_code === 400) {
            toast.error("Check your parameter");
          }
          if (error.response.data.status_code === 404) {
            toast.error(
              "Incorrect details, Account not found or exceeded attempts"
            );
          }
          if (error.response.data.status_code === 423) {
            toast.error(CustomToastWithLink(data.email));
          }
        } else {
          toast.error("Cannot Connect, Try Again Later");
        }
      });
  };
  const CustomToastWithLink = (data) => (
    <div>
      <p>
        Email needs to be verified!
        <br />{" "}
        <b onClick={() => handleResend(data)}>
          Click here to ResendVerification Email
        </b>
      </p>
    </div>
  );

  let contextData = {
    loginUser: loginUser,
    logoutUser: logoutUser,
  };
  /* const getTerms = () => {
    axios
      .get("https://auth.api.elitecloud.co.nz/terms/latest/")
      .then((res) => {
        const data = res.data.payload.version;
        setVersion(data);
        //console.log("data", data);
      })
      .catch((error) => {
        console.log("error-get", error);
      });
  }; */

  const handleUpdateTerms = (termsVersion, isAccepted) => {
    if (termsVersion !== null) {
      const postdata = {
        term_of_service_version: termsVersion,
        is_agreed: isAccepted,
      };

      const token = Cookies.get("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(
          "https://auth.api.elitecloud.co.nz/terms/update/",
          postdata,
          config
        )
        .then((response) => {
          toast(response.data.message);
          setShow(!show);
        })
        .catch((error) => {
          toast.error("Not updated");
        });
    } else {
      toast.error("Could retrieve latest Terms And Conditions");
    }
  };

  /* const onSubmit = (data) => {
    const postdata = {
      term_of_service_version: version,
      is_agreed: data.isAgreed,
    };

    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post("https://auth.api.elitecloud.co.nz/terms/update/", postdata, config)
      .then((response) => {
        toast(response.data.message);
        setShow(!show);
      })
      .catch((error) => {
        toast.error("Not updated");
      });
  }; */
  const handleResend = (resendemail) => {
    const data = {
      email: resendemail,
    };

    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/verify/account/resend/",
        data
      )
      .then((response) => {
        toast.info("Verfication link has been sent to your email");
      })
      .catch((error) => {
        if (error.response.data.status_code === 503) {
          toast.error("Failed to send email");
        }
        if (error.response.data.status_code === 429) {
          toast.error("Daily quota exceed");
        }
        if (error.response.data.status_code === 417) {
          toast.error("Account already verified");
        }
        if (error.response.data.status_code === 400) {
          toast.error("Email is empty");
        }
        if (error.response.data.status_code === 404) {
          toast.error("User with given email does not exists");
        }
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>

      <TermsModal
        show={show}
        handleClose={handleClose}
        handleUpdateTerms={handleUpdateTerms}
      />

      {/* <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <br />
              We have Updated our terms & conditions !
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="isAgreed"
                value="true"
                {...register("isAgreed", {
                  required: "Please tick 'I agree' to continue",
                })}
              />
              <label>
                To continue to login, please accept our
                <Link
                  to={{
                    pathname: "https://account.elitecloud.co.nz/terms",
                  }}
                  target="_blank"
                >
                  &nbsp;&nbsp;terms and conditions
                </Link>
              </label>
              <br />
            </div>

            <br />
            <br />
            <div className="text-danger">{errors.isAgreed?.message}</div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="outline-primary" type="submit">
              Accept
            </Button>
          </Modal.Footer>
        </form>
      </Modal> */}
    </>
  );
};
