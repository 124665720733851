import axios from "axios";
import Cookies from "js-cookie";

export const redeemCode = (code) => {
  let token = Cookies.get("access_token");
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let body = { code: code };

  try {
    let response = axios.put(
      `https://panel.api.elitecloud.co.nz/subscription/a08e3f12-1dc1-40e4-9f70-a8721b83308e/promotion_code/`,
      body,
      headers
    );

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};
