import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PasswordSecurity(props) {
  useEffect(() => {}, []);
  let history = useHistory();
  const { register, handleSubmit,getValues,formState: { errors }}=useForm({});

  const [oldpassword, setOldpassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmpassword, setConfirmpassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleOldPasswordVisiblity = () => {
    setOldpassword(oldpassword ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmpassword(confirmpassword ? false : true);
  };
 

  const onSubmit = (data) => {
console.log("data", data);
    const token = Cookies.get("access_token");
    if(data.old_password===data.new_password){
   toast.error("Please Update Your Password")
    }
    else{
    console.log("token");
    const newdata = {
      old_password: data.old_password,
      new_password: data.new_password,
    };
    console.log("resetdata", newdata);
    var formBody = [];
    for (var property in newdata) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(newdata[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log("formbody", formBody);
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/password/update/",
        formBody,
        headers
      )
      .then((response) => {
        //console.log("resetresponse",response);
        history.push("/");
      })
      .catch((error) => {
        if(error.response.data.status_code===406){toast.error("old password is Incorrect");}
        if(error.response.data.status_code===417){toast.error("Password should not contain user's account name or parts of the user's full name");}
        if(error.response.data.status_code===403){toast.error("Token is expired");}
        if(error.response.data.status_code===417){toast.error("Weak Password");}
        console.log("log error", error);
      });
    }
  };

  return (
    <>

   
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    
      <div className="tab-component">
   
          <Row>
           
            <div className="ProfileAccount">
              <h2>&nbsp;&nbsp;&nbsp;Password & Security</h2>
            </div>
          </Row>
<Row>
  <Col xs={12} md={8}>
  <Card>
            <Card.Header>Password</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
            
                <div className="password_security">
                  <div className="password_security">
                    <label>Old Password</label>

                    <InputGroup>
                      <span className="input-group-append password__secure">
                        <span className="password__secureright ">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </span>
                      <input
                        type={oldpassword ? "text" : "password"}
                        name="old_password"
                        placeholder=" Old Password"
                        autoComplete="off"
                        className=" form-control border-right-0 border"
                        {...register("old_password")}
                      />
                      <span className="input-group-append password__secure">
                        <span
                          className="password__secureleft"
                          onClick={toggleOldPasswordVisiblity}
                        >
                          {oldpassword ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </span>
                      </span>
                    </InputGroup>
                  </div>
                </div>
                <p className="text-danger"></p> 
                <div className="password_security">
                  <div className="password_security">
                    <label>New Password</label>
                    <InputGroup>
                      <span className="input-group-append password__secure">
                        <span className="password__secureright ">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="new_password"
                        placeholder="New Password"
                        autoComplete="off"
                        className=" form-control border-right-0 border"
                        {...register("new_password",
                        {
                          required: "You must specify a password",
                          minLength: {
                          value: 8,
                          message: "Too short"
                          },
                          pattern: {
                              value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                              message:"Weak Password"
                          
                          },
                         
                      })}
                      />
                      <span className="input-group-append password__secure">
                        <span
                          className="password__secureleft  "
                          onClick={togglePasswordVisiblity}
                        >
                          {passwordShown ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </span>
                      </span>
                    </InputGroup>
                    <p className="text-danger">{errors.new_password?.message}</p>  
                  </div>
                </div>
             
                <div className="password_security">
                  <div className="password_security">
                    <label>Confirm New Password</label>
                    <InputGroup>
                      <span className="input-group-append password__secure">
                        <span className="password__secureright ">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </span>
                      <input
                        type={confirmpassword ? "text" : "password"}
                        name="confirm_password"
                        placeholder="Confirm Password"
                        autoComplete="off"
                        className=" form-control border-right-0 border"
                        {...register("confirm_password",{
                          required: "Please confirm password!",
                          validate: {
                          matchesPreviousPassword: (value) => {
                              const { new_password } = getValues();
                              return new_password === value || "Passwords should match!";
                          }
                          }
                      })}
                      />
                      <span className="input-group-append password__secure">
                        <span
                          className="password__secureleft  "
                          onClick={toggleConfirmPasswordVisiblity}
                        >
                          {confirmpassword ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </span>
                      </span>
                    </InputGroup>
                    <p className="text-danger">{errors.confirm_password?.message}</p>
                  </div>
                </div>
                <br />
                <Button variant="primary" className="loginSubmit" type="submit">
                  Confirm
                </Button>
              </Form>
            </Card.Body>
          </Card>
  </Col>
</Row>
          

          <br />

      </div>
    </>
  );
}
