import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import axios from "axios";
import { Container } from "react-bootstrap";


export default function CookiePolicy() {
  const [viewterms, setViewTerms] = useState();

  useEffect(() => {
    getCookiePolicy();
  });

  const getCookiePolicy = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        //'Authorization': token,
      },
    };
    await axios
      .get("https://auth.api.elitecloud.co.nz/terms/latest/", config)
      .then((res) => {
        const data = res.data.payload.cookie_policy;
        console.log("data", data);
        setViewTerms(data);
      })
      .catch((error) => {
        console.log("error-get", error);
      });
  };
  return (
    <>
      <Container style={{ border: "1px #ccc solid" }}>
        <div className="markdown">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{viewterms}</ReactMarkdown>
        </div>
      </Container>
    </>
  );
}
