import classNames from "classnames";
import React from "react";
import { Route, Switch } from "react-router-dom";

import { Container } from "react-bootstrap";

import AccountNav from "../Profile/AccountNav";
//import Userprofile from "./Userprofile";
import CheckoutForm from "../Account/CheckoutForm";
import Subscription from "../Account/Subscription";
import BillingHistory from "../Profile/BillingHistory";
import ManageSubscriptions from "../Profile/ManageSubscriptions";
import UserEdit from "../Profile/UserEdit";
import BillingInfo from "./BillingInfo";
import CompanyProfileEdit from "./CompanyProfileEdit";
import PasswordSecurity from "./PasswordSecurity";
// import subscribeplan from "./subscribeplan";
import { Coupons } from "./Coupons/Coupons";
import EditableRow from "./EditableRow";
import ReadonlyRow from "./ReadonlyRow";
import Sitelist from "./Sitelist";
import Usersite from "./Usersite";
import viewSite from "./viewSite";
// import Autocomplete from "./Autocomplete";
import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Content = (props) => {
  const [isInstallerPlan, setIsInstallerPlan] = useState(false);
  const installerYearlyuuid = `bc468272-9fda-463e-bc3c-bd6b33558ad2`;

  const getUserSubscribtion = useCallback(() => {
    const token = Cookies.get("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // console.log("config",config)
    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)
      .then((res) => {
        if (
          res.data &&
          res.data.results &&
          Array.isArray(res.data.results) &&
          res.data.results.length > 0
        ) {
          const planUuid = res.data.results[0].plan.uuid;

          if (planUuid === installerYearlyuuid) {
            setIsInstallerPlan(true);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [installerYearlyuuid]);

  useEffect(() => {
    getUserSubscribtion();
  }, [getUserSubscribtion]);

  return (
    <>
      <div className="noticeModal">
        <Modal
          show={isInstallerPlan}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Early Access
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Thank you for signing up as part of our early access to the ECi
              Dashboard!
            </p>
            <p>
              Please sign in to{" "}
              <a
                href="https://earlyaccess.elitecloud.co.nz"
                target="_blank"
                rel="noopener noreferrer"
              >
                earlyaccess.elitecloud.co.nz
              </a>{" "}
              until the dashboard is officially released, or click the button
              below
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" style={{ float: "right" }}>
              <a
                href="https://earlyaccess.elitecloud.co.nz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to the new site
              </a>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Container
        fluid
        className={classNames("content", { "is-open": props.isOpenbar })}
      >
        <AccountNav
          toggle={props.toggle}
          userImg={props.userImg}
          profile={props.profileData}
        />
        <Switch>
          <Route exact path={"/subscription/plans"} component={Subscription} />
          <Route exact path="/profile" component={UserEdit} />
          <Route exact path="/password/manage" component={PasswordSecurity} />
          <Route exact path="/checkout" component={CheckoutForm} />
          <Route exact path="/billing" component={BillingInfo} />
          <Route exact path="/billinginfo" component={BillingHistory} />
          <Route
            exact
            path="/subscription/manage"
            component={ManageSubscriptions}
          />
          <Route
            exact
            path="/companyprofileedit"
            component={CompanyProfileEdit}
          />
          {/* <Route exact path="/subscribeplan" component={subscribeplan}/> */}
          <Route exact path="/sites" component={Sitelist} />
          <Route exact path="/viewsite" component={viewSite} />
          <Route exact path="/editable" component={EditableRow} />
          <Route exact path="/readonly" component={ReadonlyRow} />
          <Route exact path="/site" component={Usersite} />
          <Route exact path="/coupons" component={Coupons} />
        </Switch>
      </Container>
    </>
  );
};

export default Content;
