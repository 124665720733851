import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Moment from "react-moment";
//import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
export default function ManageSubscriptions() {
  const [subscribtions, setSubscribtions] = useState([]);
  useEffect(() => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)
      .then((response) => {
        // console.log("responsesubscription", response.data.results);
        setSubscribtions(response.data.results);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, []);

  const getCustomer = () => {
    const token = Cookies.get("access_token");
    // console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  return (
    <>
      <Row>
        <div className="ProfileAccount">
          <h2>Manage Subscriptions</h2>
        </div>
      </Row>

      <Row className="subshead">
        <Col xs={12} md={12}>
          <h5>Current Plan Status</h5>
        </Col>
      </Row>
      <br />
      <Row className="subshead">
        <Col xs={12} md={12}>
          <p>
            Please attach your subscription plan to the site from your site list
            to use EliteCloud service.
          </p>
          {subscribtions.map((subscribtion, Index) => (
            <div key={Index}>
              {subscribtion.is_trial === true ? (
                <>
                  <p>
                    Trial Ending on {subscribtion.plan.name} is{" "}
                    <b>
                      <Moment format="DD/MM/YYYY" add={{ days: 180 }}>
                        {subscribtion.subscribed_at}
                      </Moment>
                    </b>
                  </p>
                </>
              ) : (
                <p>
                  <b>Active &nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  {subscribtion.plan.name}
                </p>
              )}
            </div>
          ))}
        </Col>
      </Row>
      <Row>
        <Col xs={2} className="subsInfo">
          {/* <h6>Accivate Account</h6> */}
        </Col>
      </Row>
      <hr />
      
      <Row className="subshead">
        <Col xs={12}>
          <h5>Card Management</h5>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} className="subsInfo">
          <h6 onClick={getCustomer}>Change Card Details</h6>
        </Col>
      </Row>
      <hr />
      <Row className="subshead">
        <Col xs={12}>
          <h5>Change pricing plan</h5>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} className="subsInfo">
          <h6 onClick={getCustomer}>
            If you wish to change the plan, cancel the existing plan and re
            subscribe it.
          </h6>
        </Col>
      </Row>
      <hr />
      <Row className="subshead">
        <Col xs={12}>
          <h5>Close Account</h5>
        </Col>
      </Row>
      <br />
      <Row className="subshead">
        <Col xs={12}>
          <p>
            Do you wish to close your account?Please contact our support team
            via the link below to begin this process. We will be sad to see you
            go!
            <br />
            <a href="mailto:support@aap.co.nz"> support@aap.co.nz</a>
          </p>
        </Col>
      </Row>
    </>
  );
}
