import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AuthenticationLayout from "../Components/layout/AuthenticationLayout";

const StyledContainer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: "Nunito Sans", sans-serif;

  margin-top: 60px;
  padding: 60px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 5px 10px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  text-align: center;

  a {
    color: #4980be;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: red;
    cursor: unset;
  }
`;

export default function CanceledPayment() {
  return (
    <AuthenticationLayout maxWidth="700px">
      <StyledContainer>
        <h3>
          Your payment was Cancelled &nbsp;
          <StyledIcon icon={faWindowClose} color="red" />
        </h3>

        <br />

        <p>Your payment has not been processed</p>
        <p>
          If you would like to return your payment page, Click{" "}
          <Link to="/subscription/plans">subscription</Link>&nbsp;link
        </p>
      </StyledContainer>
    </AuthenticationLayout>
  );
}
