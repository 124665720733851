import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import UserImg from "./user.jpg";
//import Placeholder from './placeholder.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import CompanyProfileEdit from "./CompanyProfileEdit";
import imageCompression from "browser-image-compression";

const ProfileEdit = (props) => {
  //const [key, setKey] = useState('home');

  const [image, setImage] = useState({
    userpreview: "",
    raw: "",
    logopreview: "",
  });
  //const [,setUserImage]=useState();//link of the uploaded img
  //const [viewimage,setViewImage]=useState();
  const [currentUser, setCurrentUser] = useState({
    first_name: "",
    last_name: "",
    profile_img_url: "",
    phone_no: "",
  });
  const [phonevalues, setphoneValues] = useState({
    valuenumber: "",
    verficationCode: "",
  });
  const [values, setValues] = useState({ email: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //phone
  const [phoneshow, setphoneShow] = useState(false);
  const handlephoneClose = () => setphoneShow(false);
  const handlephoneShow = () => setphoneShow(true);
  const [showMore, setShowMore] = useState(false);
  const [showphone, setShowphone] = useState(true);

  useEffect(() => {
    function fetchData() {
      let token = Cookies.get("access_token");
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get("https://auth.api.elitecloud.co.nz/user/profile/", config)
        .then((res) => {
          const userdata = res.data.payload;

          setCurrentUser(userdata);
          getImageFromURL(token, userdata.profile_img_url, (img) => {});
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  async function handleImageChange(event) {
    const { name } = event.target;
    const imageFile = event.target.files[0];
    // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(imageFile, options);
    // console.log(
    //   "compressedFile instanceof Blob",
    //   compressedFile instanceof Blob
    // ); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    //uploadToServer(compressedFile); // write your own logic

    setImage({
      ...image,
      [name]: compressedFile, //name of the image
      userpreview: URL.createObjectURL(event.target.files[0]), //link of the browse image
    });
  }

  function getImageFromURL(token, url, completion) {
    //console.log("getImageFromURLtoken")
    //let retry = false;
    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => {
        completion(r);
      })
      .catch((e) => {
        if (e.request.responseURL.match(/s3.amazonaws.com/)) {
          completion(e.request.responseURL);
        }
      });
  }

  function handleDataSubmit(e) {
    e.preventDefault();
    if (image.raw) {
      const formData = new FormData();
      formData.append("image", image.raw);
      const token = Cookies.get("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post("https://file.elitecloud.co.nz/image/upload/", formData, config)
        .then((res) => {
          const imagedata = res.data.url;
          currentUser.profile_img_url = imagedata;
          //setUserImage(imagedata);
          handleSubmit(e);
          toast.info("Uploaded");
        })
        .catch((error) => {
          // console.log("error", error);
        });
    } else {
      handleSubmit(e);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    //handleUpload(e);
    const data = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      username: currentUser.username,
      profile_img_url: currentUser.profile_img_url,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("config", config);
    axios
      .patch(
        "https://auth.api.elitecloud.co.nz/user/profile/",
        formBody,
        config
      )
      .then((res) => {
        //toast.info("Updated");
        window.location.reload();
        //history.push('/userEdit');
      })
      .catch((error) => {
        // console.log("erroronsubmit", error);
      });
  }

  const emailhandleSubmit = (event) => {
    event.preventDefault();

    handleShow();
    const data = {
      email: values.email,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/profile/email/",
        formBody,
        config
      )
      .then((res) => {
        const data = res.data.payload;

        setValues(data);
        toast.info("Verification request link has been  message sent!");
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  const emailhandleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //phone
  const phonehandleChange = (e) => {
    setphoneValues({ ...phonevalues, valuenumber: e });
  };
  const handleCodeChange = (e) => {
    const { name, value } = e.target;
    setphoneValues({ ...phonevalues, [name]: value });
  };

  const phonehandleSubmit = (event) => {
    event.preventDefault();

    handlephoneShow();
    const data = {
      phone_no: phonevalues.valuenumber,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/profile/phone/",
        formBody,
        config
      )
      .then((res) => {
        const phonedata = res;

        setphoneValues(phonedata);
        setShowMore(!showMore);
        setShowphone(!setShowphone);
        toast.info(res.data.message);
        setphoneValues(phonedata);
      })
      .catch((error) => {
        if (error.response.data.status_code === 409) {
          toast.error(" This phone number already registered!");
        }
        if (error.response.data.status_code === 400) {
          toast.error(" Please enter a valid phone Number!");
        }
        if (error.response.data.status_code === 403) {
          toast.error(" Session Time out!please login and try again!");
        }
        if (error.response.data.status_code === 408) {
          toast.error(" Verification timeout");
        }
        if (error.response.data.status_code === 429) {
          toast.error("Maximum daily verification request exceed! ");
        }
        // console.log("error", error.message);
      });
  };
  const phonehandleCodeSubmit = (event) => {
    event.preventDefault();
    handlephoneShow();
    const data = {
      phone_no: phonevalues.valuenumber,
      verification_number: phonevalues.verficationCode,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/profile/phone/",
        formBody,
        config
      )
      .then((res) => {
        const data = res.data.payload;

        toast.info(res.data.message);
        setphoneShow(!phoneshow);
        setphoneValues(data);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.message);
        // console.log("error", error.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Row>
        <div className="ProfileAccount">
          <h2>Profile</h2>
        </div>
      </Row>

      <Row>
        <Col xs={12} md={11} lg={6}>
          <Card className="text-center editcard">
            <Card.Header as="h5"></Card.Header>
            <Card.Body>
              <Tabs
                defaultActiveKey="home"
                id="controlled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="User Profile">
                  <form onSubmit={handleDataSubmit}>
                    <Row>
                      <Col xs={12} sm={12} md={3} lg={3}>
                        <Card.Title className="profylImg">

                          {image.userpreview ? (
                            <>
                              <img
                                width="100px"
                                src={image.userpreview}
                                alt="userimage"
                              />
                              <div className="upload"></div>
                            </>
                          ) : (
                            <>
                              {currentUser.profile_img_url ? (
                                <>
                                  <img
                                    src={currentUser.profile_img_url}
                                    roundedCircle
                                    width="100px"
                                    alt="profile"
                                  />
                                  <br />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={UserImg}
                                    roundedCircle
                                    width="100px"
                                    alt="profile"
                                  />
                                </>
                              )}
                            </>
                          )}

                          <div className="overlayProfile">
                            <div className="icon" title="User Profile">
                              <label htmlFor="upload-button">
                                <FontAwesomeIcon icon={faCamera} />
                              </label>
                            </div>
                          </div>
                          <input
                            type="file"
                            id="upload-button"
                            name="raw"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                          />
                        </Card.Title>
                        <br />
                        <br />
                        <br />

                        {/* <button onClick={getImage}>getImage</button> */}
                      </Col>
                      <Col
                        xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        className="profylDetail"
                      >
                        <Row>
                          <Col>
                            <Card.Title className="profylTitle">
                              First Name
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Text>
                              <Form.Control
                                type="text"
                                name="first_name"
                                value={currentUser.first_name}
                                onChange={handleChange}
                                placeholder={currentUser.first_name}
                              />
                            </Card.Text>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Card.Title className="profylTitle">
                              Last Name
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Text>
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={currentUser.last_name}
                                onChange={handleChange}
                                placeholder={currentUser.last_name}
                              />
                            </Card.Text>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Card.Title className="profylTitle">
                              Email
                            </Card.Title>
                          </Col>
                          <Col>
                            <Card.Text>
                              <Form.Control
                                onClick={handleShow}
                                type="text"
                                name="email"
                                value={currentUser.email}
                                onChange={handleChange}
                                placeholder={currentUser.email}
                              />
                            </Card.Text>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col></Col>
                          <Col>
                            <div className="saveBtn">
                              <Button
                                variant="outline-primary"
                                type="submit"
                                style={{ width: "100%" }}
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </Tab>
                {/* <Tab eventKey="profile" title="Company profile">
                  <CompanyProfileEdit />
                </Tab> */}
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* modal for email */}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Email Verification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={emailhandleSubmit}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={emailhandleChange}
                  placeholder="New Email Address"
                />
              </Col>
            </Row>
            <br />
            <br />
            <Button variant="primary" className="modalPhone" type="=submit">
              Verify Your Existing Email
            </Button>
            <br />
            <br />
            <br />
          </form>
          In order to protect the security of your account, we will send you a
          verification email to your existing account. Your new email will not
          be updated without verification.
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* phone */}

      <Modal show={phoneshow} onHide={handlephoneClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Phone Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Button onClick={handleMoreClick}></Button> */}
          In order to protect the security of your account, we will send you a
          text message with a verification code
          <br />
          <br />
          <br />
          {/* {values.verficationCode=null?<h2>hellooo</h2>:<h2>no verification code</h2>} */}
          {showphone && (
            <form onSubmit={phonehandleSubmit}>
              <Row>
                <Col>
                  <Card.Text>
                    <PhoneInput
                      placeholder="phone number"
                      value={phonevalues.valuenumber}
                      onChange={phonehandleChange}
                    />
                  </Card.Text>
                </Col>
              </Row>
              <br />

              <Button variant="primary" className="modalPhone" type="=submit">
                Verify&nbsp;&nbsp;
              </Button>
              <br />
              <br />
            </form>
          )}
          {showMore && (
            <form onSubmit={phonehandleCodeSubmit}>
              <Row>
                <Col>
                  <Card.Text>
                    <PhoneInput
                      placeholder="phone number"
                      value={phonevalues.valuenumber}
                      onChange={phonehandleChange}
                    />
                  </Card.Text>
                  <Form.Control
                    name="verficationCode"
                    value={phonevalues.verficationCode}
                    onChange={handleCodeChange}
                    placeholder="Leave your Verification here"
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Button variant="primary" className="modalPhone" type="=submit">
                Submit
              </Button>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlephoneClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ProfileEdit;
