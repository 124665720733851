import React from "react";
import styled from "styled-components";

const StyledDivider = styled.hr`
  margin: 0.5rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  opacity: 1;
`;

const Divider = () => {
  return <StyledDivider />;
};

export default Divider;
