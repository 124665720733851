import React, { useContext, useState } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import "../App.css";
// import AOS from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Col,
  Form,
  Button,
  InputGroup,
  
} from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import {  ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import NavbarSec from '../Shared/NavbarSec';
import Footer from "../Shared/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Img/Logo.png";
import AuthContext from "../Context/AuthContext";

const Login = () => {
  let { loginUser } = useContext(AuthContext);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <Container fluid>
      <div className="home">
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="loginHead">
          <Link to={{ pathname: "https://elitecloud.co.nz/" }} target="_blank">
            <img src={Logo} alt="logo" style={{ width: "80px" }} />
          </Link>
          <h2>Sign In Below</h2>
          <small>
            or, <Link to="/register">Sign Up </Link>here
          </small>
        </div>

        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Form onSubmit={loginUser} className="loginSec">
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Email" />
            </Form.Group>

            <div className="password_security">
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <div className="password_security">
                  

                  <InputGroup>
                    <span className="input-group-append password__secure">
                      <span className="password__secureright ">
                        <FontAwesomeIcon icon={faLock} />
                      </span>
                    </span>
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="password"
                      autoComplete="off"
                      className="form-control border-right-0 border"
                    />
                    <span className="input-group-append password__secure">
                      <span
                        className="password__secureleft"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </span>
                    </span>
                  </InputGroup>
                </div>
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              {/* <Form.Check
              type="checkbox"
              name="rememberMe"
              checked={this.state.rememberMe}
              onChange={this.handleChange}
              label="Remember me"
            /> */}
              <br />
            </Form.Group>

            <Button variant="primary" className="loginSubmit" type="submit">
              Sign In
            </Button>
            <br />
            <Form.Group>
              <br />
              <Form.Label className="fogtPsswrd">
                <HashLink smooth to="/resetpassword#top">
                  Forgot Your Password?
                </HashLink>
              </Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </div>
      <Footer />
    </Container>
  );
};

export default Login;
