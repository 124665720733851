import React,{useState,useEffect} from "react";
import { Container,Table } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import ReadonlyRow from "./ReadonlyRow";
import EditableRow from "./EditableRow";

const ViewSite = () => {
  const [contacts,setContacts]= useState([]);
  const [editcontactid,setEditcontactid]=useState(null)
  useEffect(() => {
    let token = Cookies.get("access_token");
    const config = {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     };
     axios
       .get(
         `https://panel.api.elitecloud.co.nz/subscription/`,config
       )
       .then((response) => {
         console.log("contacts", response.data.results);
         setContacts(response.data.results)
       })
       .catch((error) => {
         console.log("error", error);
       });  
  },[]);


  const handleEditClick=(event,contact)=>{
    alert(contact.uuid)
    event.preventDefault();
    setEditcontactid(contact.uuid)
  }

  return (
    <>
    <Container>
    <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>uuid</th>
      <th>Name</th>
      <th>Actions</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
{contacts.map((contact)=><>
{editcontactid===contact.uuid ? <EditableRow  contact={contact}/>:
<ReadonlyRow contact={contact} handleEditClick={handleEditClick}/>}


</>)}
    
    
  </tbody>
</Table>
    </Container>
    </>
  )
}

export default ViewSite