import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "../../App.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import GenerateCoupon from "./GenerateCoupon";
import axios from "axios";
import Cookies from "js-cookie";
import LoadingSpinner from "../../Shared/LoadingSpinner";
import RedeemCoupon from "./RedeemCoupon";
import { useHistory } from "react-router-dom";

export const Coupons = () => {
  const [key, setKey] = useState("generate");
  const [hasPlan, setHasPlan] = useState(false);
  const [subscribeWindow, setSubscribeWindow] = useState("");
  const [isHomePlan, setHomePlan] = useState(false);
  const [subId, setSubId] = useState("");

  const history = useHistory();

  useEffect(() => {
    let token = Cookies.get("access_token");

    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", headers)
      .then((response) => {
        if (response.data.results[0]) {
          if (response.data.results[0].plan.name.includes("Home")) {
            setHomePlan(true);
          }
          if (response.data.results[0].plan.uuid) {
            setHasPlan(true);
            setSubId(response.data.results[0].plan.uuid);
          }
        } else {
          setHasPlan(false);
          axios
            .get(
              "https://panel.api.elitecloud.co.nz/subscription/plan/a08e3f12-1dc1-40e4-9f70-a8721b83308e/subscribe/",
              headers
            )
            .then((response) => {
              setSubscribeWindow(response.data.payload.url);
            })
            .catch((error) => {
              console.log("An Error has occured", error);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleSelect = (k) => {
    if (k === "redeem" && !hasPlan) {
      history.push("/subscription/plans/");
    } else {
      setKey(k);
    }
  };

  return (
    <div>
      <div className="coupon-main">
        <div className="ProfileAccount">
          <h2>Eligible For a Coupon?</h2>
        </div>
        <h3 className="couponDes">
          We are offering 'EliteCloud - Home Plan' free for 2 years when you
          upgrade from the 'EliteControl' app. A security installer is required
          to update your alarm network module & set you up on the new
          'EliteCloud' platform. Contact{" "}
          <a href="mailto: support@aap.co.nz">support@aap.co.nz</a> or view our{" "}
          <a href="https://elitecloud.co.nz/upgrade">Upgrade page</a> for more
          information or a recommended installer in your area.
        </h3>

        <div>
          {subscribeWindow || hasPlan ? (
            <Card className="billingCard">
              <Card.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => handleSelect(k)}
                  className="mb-3"
                >
                  <Tab eventKey="generate" title="Generate">
                    <GenerateCoupon hasPlan={hasPlan} isHomePlan={isHomePlan} />
                  </Tab>

                  <Tab eventKey="redeem" title="Redeem">
                    <RedeemCoupon subId={subId} />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
};
