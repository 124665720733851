import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../footer/Footer";

export default function ContentWithFooter({ maxwidth, children }) {
  return (
    <Container
      fluid
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container fluid {...(maxwidth && { style: { maxWidth: maxwidth } })}>
        {children}
      </Container>
      <Footer>{`© ${new Date().getFullYear()} EliteCloud. All rights reserved.`}</Footer>
    </Container>
  );
}
