import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

import Cookies from "js-cookie";
import axios from "axios";
import { Container, Button } from "react-bootstrap";
import Sidebar from "./Sidebar";
import IdleTimer from "react-idle-timer";
import Modal from "react-modal";
// import Search from "./Address"
// import Address2 from "./Address2";
// import CompanyProfile from "./CompanyProfile";
import Content from "./Content";
import classNames from "classnames";
import Footer from "./footer";
const Layout = (props) => {

  const [isOpenbar, setOpenbar] = useState(true);
  const widthLimit = 850;

  useEffect(() => {
    if (window.innerWidth <= widthLimit) {
      setOpenbar(false);
    } else {
      setOpenbar(true);
    }
  }, []);

  const toggle = () => {
    setOpenbar(!isOpenbar);
  };

  //-------------------------sidebaruseffect ends

  let history = useHistory();
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  Modal.setAppElement("#root");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const isAuthenticated = Cookies.get("access_token");
  const exptime = Cookies.get("exp_time");
  const [count] = useState();
  const [user, setUser] = useState({});

  const customStyles = {
    content: {
      top: "45%",
      left: "55%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      textAlign: "center",
      transform: "translate(-50%, -50%)",
    },
  };

  const logoutUser = useCallback(() => {
    setmodalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    Cookies.remove("access_token");
    Cookies.remove("exp_time");
    history.push("/login");
  }, [history]);

  const stayActive = () => {
    setmodalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    const data = {
      access_token: Cookies.get("access_token"),
      refresh_token: Cookies.get("refresh_token"),
    };

    console.log("data", data);
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    let token = Cookies.get("access_token");
    const headers = {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    };

    axios
      .post(
        "https://auth.api.elitecloud.co.nz/user/token/refresh/",
        formBody,
        headers
      )
      .then((response) => {
        //console.log("refresh token response", response);
        if (response.status === 200) {
          Cookies.set("exp_time", response.data.payload.expire_at, {
            domain: ".elitecloud.co.nz",
          });
          Cookies.set("access_token", response.data.payload.access_token, {
            domain: ".elitecloud.co.nz",
          });
          Cookies.set("refresh_token", response.data.payload.refresh_token, {
            domain: ".elitecloud.co.nz",
          });
        }
      })
      .catch((error) => {
        history.push("/login");
      });
  };
  const onIdle = () => {
    // alert("user is idle");
    console.log("user is idle");
    setmodalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(logoutUser, 1000 * 60 * 3);
  };

  //-------------------main useffect starts
  useEffect(() => {
    try {
      let expdate = new Date(exptime);
      //console.log("expdate",expdate)
      let nowdate = new Date();
      //console.log("nowdate",nowdate)

      if (isAuthenticated && expdate >= nowdate) {
      } else {
        history.push("/login");
      }

      UserDetails();
    } catch (ex) {}
  }, [count, exptime, history, isAuthenticated, logoutUser]);

  const UserDetails = () => {
    Cookies.set("isreloaded", true);
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://auth.api.elitecloud.co.nz/user/profile/", config)
      .then((res) => {
        const data = res.data.payload;
        //console.log("useronlayout",res.data.payload)
        setUser(data);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  //-------------------main useffect ends-------

  return (
    <>
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <h2>You've been Idle for a while</h2>
        <br />
        <p>You will be logged out soon </p>
        <Button variant="btn btn-outline-primary" onClick={logoutUser}>
          Log me out
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          className="btnStayIn"
          variant="btn-outline-primary"
          onClick={stayActive}
        >
          Stay signed in
        </Button>
      </Modal>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 60 * 6}
        onIdle={onIdle}
      ></IdleTimer>
      <div className="App wrapper">
        <Sidebar toggle={toggle} isOpenbar={isOpenbar} />
        <Container
          fluid
          className={classNames("content", { "is-open": isOpenbar })}
        >
          <Content
            loggedIn={UserDetails}
            userImg={user.profile_img_url}
            profileData={user}
            toggle={toggle}
            isOpenbar={isOpenbar}
          />
          <Footer />
        </Container>
      </div>
    </>
  );
};

export default Layout;
