import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { redeemCode } from "./helpers";

const GenerateCoupon = ({ hasPlan, isHomePlan }) => {
  const [couponCode, setCouponCode] = useState("Test123");
  const [isDisplayCoupon, setDisplayCoupon] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const requestCoupon = () => {
    var macAddress = document.getElementById("mac").value;
    var serialNo = document.getElementById("serial").value;
    console.log("Mac & Serial ", macAddress, serialNo);

    const notify = (message) => toast.error(message);

    if (macAddress && serialNo) {
      let token = Cookies.get("access_token");
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let body = {
        mac_address: macAddress,
        serial_no: serialNo,
      };

      axios
        .post(
          `https://panel.api.elitecloud.co.nz/subscription/redeem/upgrade/`,
          body,
          headers
        )
        .then((response) => {
          setDisplayCoupon(true);
          setCouponCode(response.data.payload.code);

          if (hasPlan && isHomePlan) {
            let redeemPromise = redeemCode(response.data.payload.code);

            redeemPromise.then((res) => {
              if (res.data.status_code === 202) {
                toast.info("Coupon Successfully Redeemed");
                setIsSuccess(true);
              } else {
                notify("Failed to Redeem Code");
              }
            });
          }

          if (hasPlan && !isHomePlan) {
            notify("You need to be on home plan to redeem this.");
          }
        })
        .catch((error) => {
          notify("Failed to generate coupon");
        });
    } else {
      notify("Please enter a mac and serial");
    }
  };

  const formatMac = (e) => {
    e.target.value = (
      e.target.value
        .toUpperCase()
        .replace(/[^\d|A-F]/g, "")
        .match(/.{1,2}/g) || []
    ).join("-");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(couponCode);
    toast.info("Copied to Clipboard");
  };

  const redirectBilling = () => {
    const token = Cookies.get("access_token");
    console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <ToastContainer />
      {isSuccess ? (
        <>
          <h5 style={{ textAlign: "center" }}>
            Your coupon was succesfully applied and will be applied for the next
            payment period!
          </h5>
          <Button
            variant="primary"
            style={{ marginTop: "15px" }}
            onClick={redirectBilling}
          >
            Go to Billing
          </Button>
        </>
      ) : (
        <>
          {isDisplayCoupon && !hasPlan ? (
            <>
              <Row style={{ marginTop: "30px" }} className="coupon_code">
                <Col xs={12}>
                  <h2 onClick={copyToClipboard}>
                    <FontAwesomeIcon
                      className="coupon_code"
                      icon={faCopy}
                      style={{ marginRight: "15px" }}
                    />{" "}
                    {couponCode}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Please copy your coupon above and enter it in the 'Add
                    promotion code' field on Stripe. Clicking the 'Redeem' tab
                    above will take you to Stripe to finalise your subscription.
                  </p>
                </Col>
              </Row>
            </>
          ) : (
            <div>
              <Form>
                <Form.Group>
                  <Form.Label>Mac Address</Form.Label>
                  <Form.Control type="text" id="mac" onChange={formatMac} />
                  <Form.Label style={{ marginTop: "10px" }}>
                    Serial Number
                  </Form.Label>
                  <Form.Control type="text" id="serial" />
                </Form.Group>
                <Button
                  variant="primary"
                  style={{ marginTop: "15px" }}
                  onClick={requestCoupon}
                >
                  {hasPlan ? "Redeem" : "Generate"}
                </Button>
              </Form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GenerateCoupon;
