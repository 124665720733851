import React from 'react'

const EditableRow = ({contact}) => {
  return (
    <tr>
        <td>Hello editing section</td>
       <td>{contact.uuid}</td>
       <td>{contact.plan.name}</td>
     </tr>
  )
}

export default EditableRow