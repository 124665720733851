import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import axios from "axios";
import {
  Col,
  Row,
  Container,
  Card,
  ListGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faHouseBuilding,
  faUserHelmetSafety,
  faBuilding,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

const YearSubscription = (props) => {
  console.log("props", props);
  // const [subscriptionuuid, setSubscriptionuuid] = useState("");
  const betauuid = `39ad070e-d73d-48d0-b998-6b884914c545`;
  const yearHomeuuid = `a08e3f12-1dc1-40e4-9f70-a8721b83308e`;
  const yearMultiuuid = `4acf9af2-b5a2-4502-bb75-64ea76bf13bb`;
  const yearEnterpriseuuid = `c962204a-337b-4e20-b656-a453ee51120c`;
  const [yearlyBasemodal, setYearlyBasemodal] = useState(false);
  const [yearlyMultimodal, setYearlyMultimodal] = useState(false);
  const [yearlyEnterprisemodal, setYearlyEnterprisemodal] = useState(false);
  const handleOpenYearlyBasemodal = () => setYearlyBasemodal(true);
  const handleCloseYearlyBasemodal = () => setYearlyBasemodal(false);
  const handleOpenYearlyMultimodal = () => setYearlyMultimodal(true);
  const handleCloseYearlyMultimodal = () => setYearlyMultimodal(false);
  const handleOpenYearlyEnterprisemodal = () => setYearlyEnterprisemodal(true);
  const handleCloseYearlyEnterprisemodal = () =>
    setYearlyEnterprisemodal(false);
  const [setCurrentplancolor] = useState("#4980be");
  const [showUpgradeSubscriptionModal, setShowUpgradeSubscriptionModal] =
    useState(false);
  const [redirectingToBilling, setRedirectingToBilling] = useState(false);

  let otherplancolor = "#4980be";

  useEffect(() => {});

  const redirectToYearlyHomeCheckout = async () => {
    let token = Cookies.get("access_token");
    //console.log("token", token);
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (props.subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/a08e3f12-1dc1-40e4-9f70-a8721b83308e/update/",
          headers
        )
        .then((response) => {
          setYearlyBasemodal(false);

          toast.info("Subscription is Updated");
          window.location.reload();
          setCurrentplancolor("green");
        })
        .catch((error) => {
          // if (error.response.data.status_code === 406) {
          //   toast.error("User cannot have more than one subscription");
          // }
          // if (error.response.data.status_code === 422) {
          //   toast.error("Subscription is unavailable.");
          // }
          console.log(error);
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/a08e3f12-1dc1-40e4-9f70-a8721b83308e/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          setCurrentplancolor("green");
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
          console.log(error);
        });
    }
  };

  const redirectToYearlyMultiCheckout = () => {
    let token = Cookies.get("access_token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (props.subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/4acf9af2-b5a2-4502-bb75-64ea76bf13bb/update/",
          headers
        )
        .then((response) => {
          setYearlyMultimodal(false);
          toast.info("Subscription is Updated");
          window.location.reload();
          setCurrentplancolor("green");
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
          console.log(error);
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/4acf9af2-b5a2-4502-bb75-64ea76bf13bb/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          setCurrentplancolor("green");
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          otherplancolor = "#4980be";
        });
    }
  };

  const redirectToYearlyEnterpriseCheckout = () => {
    let token = Cookies.get("access_token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (props.subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/c962204a-337b-4e20-b656-a453ee51120c/update/",
          headers
        )
        .then((response) => {
          setYearlyEnterprisemodal(false);
          toast.info("Subscription is Updated");
          window.location.reload();
          setCurrentplancolor("green");
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/c962204a-337b-4e20-b656-a453ee51120c/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          setCurrentplancolor("green");
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          otherplancolor = "#4980be";
        });
    }
  };

  const redirectToBillingPage = () => {
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setRedirectingToBilling(true);
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Col xs={12} md={12} lg={11}>
        <div className="SubscribeTabs">
          <Row className="SubscribeSec">
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card className="text-center">
                <Card.Header>
                  <h4 className="SubscribeIcon">
                    <FontAwesomeIcon icon={faHouse} />
                  </h4>
                  <p>
                    <b>Home Plan</b>
                  </p>
                  <p>Sign up to home with 1 site & up to 5 users per site</p>
                  <h3>
                    $17.49<small className="text-muted"> per year</small>
                  </h3>
                </Card.Header>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;1 Site
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;5 Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real time zone status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor snooze function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Push notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                {!props.subscriptionuuid ? (
                  <>
                    <Card.Footer
                      className="text-muted"
                      onClick={redirectToYearlyHomeCheckout}
                    >
                      Basic Plan
                    </Card.Footer>
                  </>
                ) : (
                  <>
                    {props.subscriptionuuid === betauuid ? (
                      <>
                        <Card.Footer className="text-mutedBeta">
                          Basic Plan
                        </Card.Footer>
                      </>
                    ) : (
                      <>
                        {yearHomeuuid === props.subscriptionuuid ? (
                          <>
                            <Card.Footer
                              className="text-mutedCurrent"
                              style={{
                                backgroundColor: "green",
                              }}
                              onClick={handleOpenYearlyBasemodal}
                            >
                              Current Plan
                            </Card.Footer>
                          </>
                        ) : (
                          <>
                            <Card.Footer
                              className="text-muted"
                              style={{
                                backgroundColor: otherplancolor,
                              }}
                              onClick={() =>
                                setShowUpgradeSubscriptionModal(true)
                              }
                            >
                              Basic Plan
                            </Card.Footer>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Card>
            </Col>

            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <Card className="text-center">
                <Card.Header>
                  <h4 className="SubscribeIcon">
                    <FontAwesomeIcon icon={faHouseBuilding} />
                  </h4>
                  <p>
                    <b>Multi Plan</b>
                  </p>
                  <p>Sign up to multi with 4 sites & up to 10 users per site</p>
                  <h3>
                    $29.49<small className="text-muted"> per year</small>
                  </h3>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;4 Sites
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;10 Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real time zone status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor snooze function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Push notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                {!props.subscriptionuuid ? (
                  <>
                    {" "}
                    <Card.Footer
                      className="text-muted"
                      onClick={redirectToYearlyMultiCheckout}
                    >
                      Multi Plan
                    </Card.Footer>
                  </>
                ) : (
                  <>
                    {" "}
                    {props.subscriptionuuid === betauuid ? (
                      <>
                        <Card.Footer className="text-mutedBeta">
                          Multi Plan
                        </Card.Footer>
                      </>
                    ) : (
                      <>
                        {yearMultiuuid === props.subscriptionuuid ? (
                          <>
                            <Card.Footer
                              className="text-mutedCurrent"
                              style={{
                                backgroundColor: "green",
                              }}
                              onClick={handleOpenYearlyMultimodal}
                            >
                              Current Plan
                            </Card.Footer>
                          </>
                        ) : (
                          <>
                            <Card.Footer
                              style={{
                                backgroundColor: otherplancolor,
                              }}
                              className="text-muted"
                              onClick={() =>
                                setShowUpgradeSubscriptionModal(true)
                              }
                            >
                              Upgrade to Multi
                            </Card.Footer>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3}>
              <Card className="text-center">
                <Card.Header>
                  <h4 className="SubscribeIcon">
                    <FontAwesomeIcon icon={faBuilding} />
                  </h4>
                  <p>
                    <b>Enterprise Plan</b>
                  </p>
                  <p>
                    Sign up to enterprise with 10 sites, up to 100 users per
                    site & management dashboard.
                  </p>
                  <h3>
                    $119.99
                    <small className="text-muted"> per year</small>
                  </h3>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;10 Sites & up to 100 Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;User management dashboard
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Arm & disarm
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Output control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Real time zone status
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Sensor snooze function
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Push notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                {!props.subscriptionuuid ? (
                  <>
                    <Card.Footer
                      className="text-muted"
                      onClick={redirectToYearlyEnterpriseCheckout}
                    >
                      Enterprise Plan
                    </Card.Footer>
                  </>
                ) : (
                  <>
                    {props.subscriptionuuid === betauuid ? (
                      <>
                        <Card.Footer className="text-mutedBeta">
                          Enterprise Plan
                        </Card.Footer>
                      </>
                    ) : (
                      <>
                        {yearEnterpriseuuid === props.subscriptionuuid ? (
                          <>
                            <Card.Footer
                              disable
                              className="text-mutedCurrent"
                              onClick={handleOpenYearlyEnterprisemodal}
                              style={{
                                backgroundColor: "green",
                              }}
                            >
                              Current Plan
                            </Card.Footer>
                          </>
                        ) : (
                          <>
                            <Card.Footer
                              className="text-muted"
                              onClick={() =>
                                setShowUpgradeSubscriptionModal(true)
                              }
                            >
                              Upgrade to Enterprise
                            </Card.Footer>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3}>
              <Card className="text-center">
                <Card.Header>
                  <h4 className="SubscribeIcon">
                    <FontAwesomeIcon icon={faUserHelmetSafety} />
                  </h4>
                  <p>
                    <b>Installer Plan</b>
                  </p>
                  <p>Sign up to installers management dashboard & app plan</p>
                  <h3>
                    $119.99
                    <small className="text-muted"> per year</small>
                  </h3>
                </Card.Header>

                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Over the air programming & backup
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Over the air updates
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Unlimited dashboard sites
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;4 App Sites
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;10 App Users
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Alarm Status & Control
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Comprehensive System History
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <FontAwesomeIcon icon={faCheck} />
                            &nbsp;&nbsp;&nbsp;Notifications
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
                <Card.Footer
                  className="text-muted"
                  // onClick={redirectToYearlyHomeCheckout}
                >
                  Coming Soon
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
        {/* modals */}
        <div className="noticeModal">
          <Modal
            show={yearlyBasemodal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "12px" }}>
                <span
                  style={{
                    color: "#333",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  Home plan
                </span>
                <br />
                <br />
                <br />
                Press confirm to switch to <b>home plan</b> with 1 site & up to
                5 users.
                <br />
                You will be charged $17.49 per year for this service.
                <br />
                Please note that this action is non refundable.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-secondary"
                onClick={handleCloseYearlyBasemodal}
              >
                Cancel
              </Button>
              <Link to="/subscription/plans">
                <Button
                  variant="primary"
                  onClick={redirectToYearlyHomeCheckout}
                  style={{ float: "right" }}
                >
                  Confirm
                </Button>
              </Link>
            </Modal.Body>
          </Modal>
        </div>
        <div className="noticeModal">
          <Modal
            show={yearlyMultimodal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "12px" }}>
                <span
                  style={{
                    color: "#333",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  Multi plan
                </span>
                <br />
                <br />
                <br />
                Press confirm to switch to <b>Multi plan</b> with 4 site & up to
                10 users.
                <br />
                You will be charged $29.49 per year for this service.
                <br />
                Please note that this action is non refundable.
                {props.isHome ? (
                  <>
                    <br />
                    and any applied coupons will be overwritten.
                  </>
                ) : (
                  <>.</>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-secondary"
                onClick={handleCloseYearlyMultimodal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={redirectToYearlyMultiCheckout}
                style={{ float: "right" }}
              >
                Confirm
              </Button>
            </Modal.Body>
          </Modal>
        </div>
        <div className="noticeModal">
          <Modal
            show={yearlyEnterprisemodal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "12px" }}>
                <span
                  style={{
                    color: "#333",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  Enterprise plan
                </span>
                <br />
                <br />
                <br />
                {/* You will be charged $10 permonth.<br/>
                Confirm switching to <b>Enterprise plan</b> with 10 site & up to 100 users?? */}
                Press confirm to switch to <b>Enterprise plan</b> with 10 site &
                up to 100 users.
                <br />
                You will be charged $119.99 per year for this service.
                <br />
                Please note that this action is non refundable.
                {props.isHome ? (
                  <>
                    <br />
                    and any applied coupons will be overwritten.
                  </>
                ) : (
                  <>.</>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                variant="outline-secondary"
                onClick={handleCloseYearlyEnterprisemodal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={redirectToYearlyEnterpriseCheckout}
                style={{ float: "right" }}
              >
                Confirm
              </Button>
            </Modal.Body>
          </Modal>
        </div>

        <div className="noticeModal">
          <Modal
            show={showUpgradeSubscriptionModal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "12px" }}>
                Upgrade Subscription
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Please cancel your current plan and subscribe to the new plan.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={() => setShowUpgradeSubscriptionModal(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={redirectToBillingPage}
                style={{ float: "right" }}
              >
                {redirectingToBilling ? "Redirecting..." : "Cancel Plan"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* modals ended */}
      </Col>{" "}
    </>
  );
};

export default YearSubscription;
