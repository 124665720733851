import React from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Divider from "../divider/Divider";

const StyledText = styled.p`
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  margin: 0 0 1rem 0;
  color: rgba(0, 0, 0, 0.87);

  @media (min-width: 768px) {
    margin: 1rem 0 2rem 0;
  }
`;

const ECFooter = ({ children }) => {
  return (
    <Container>
      <Divider />
      <StyledText>{children}</StyledText>
    </Container>
  );
};

export default ECFooter;
