import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import axios from "axios";
import logo from "../Img/Logo.png";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
//import icons from react icons
import { FaUser } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import { BsLaptop } from "react-icons/bs";
import { MdPayment } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { FaTag } from "react-icons/fa";
import { useState } from "react";

const Sidebar = (props) => {
  const [checkPlan, setCheckPlan] = useState([]);

  useEffect(() => {
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)
      .then((response) => {
        response.data.results.map((checkBeta) => setCheckPlan(checkBeta.plan));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleClick = () => {
    window.location.replace("https://site.dashboard.elitecloud.co.nz/");
  };

  return (
    <>
      <div className={classNames("sidebar", { "is-open": props.isOpenbar })}>
        <div className="sidebar-header">
          <FontAwesomeIcon
            icon={faAlignLeft}
            pull="right"
            size="xs"
            onClick={props.toggle}
            style={{ fontSize: "18px", color: "#4980be", marginTop: "30px" }}
          />
          {/* <FontAwesomeIcon icon={faTimes} pull="right" size="xs" /> */}

          <h3>
            <img src={logo} width="40px" alt="logo" />
            &nbsp;Elite<b>Cloud</b>
          </h3>
        </div>

        <Nav className="flex-column pt-2 hidemobile">
          <NavLink to="/site" className="nav-item">
            <span className="pro-icon-wrapper">
              <FaHome className="mr-2" />
            </span>
            <span className="siderbarSpan">Site </span>
          </NavLink>
          <NavLink to="/subscription/plans" className="nav-item">
            <span className="pro-icon-wrapper">
              <BsListCheck className="mr-2" />
            </span>
            <span className="siderbarSpan">Plans</span>
          </NavLink>
          <NavLink to="/profile" className="nav-item">
            <span className="pro-icon-wrapper">
              <FaUser className="mr-2" />
            </span>
            <span className="siderbarSpan">Profile</span>
          </NavLink>
          <NavLink to="/password/manage" className="nav-item">
            <span className="pro-icon-wrapper">
              <FaLock className="mr-2" />
            </span>
            <span className="siderbarSpan">Security</span>
          </NavLink>
          <NavLink to="/billing" className="nav-item">
            <span className="pro-icon-wrapper">
              <MdPayment className="mr-2" />
            </span>
            <span className="siderbarSpan">Billing</span>
          </NavLink>

          <NavLink to="/coupons" className="nav-item">
            <span className="pro-icon-wrapper">
              <FaTag className="mr-2" />
            </span>
            <span className="siderbarSpan">Coupons</span>
          </NavLink>

          {checkPlan.name === "Beta Plan" ? (
            <>
              <NavLink
                to="/dashboard"
                onClick={handleClick}
                className="nav-item"
              >
                <span className="pro-icon-wrapper">
                  <BsLaptop className="mr-2" />
                </span>
                <span className="siderbarSpan">Dashboard</span>
              </NavLink>
            </>
          ) : (
            <></>
          )}
        </Nav>
        <Nav className="flex-column pt-2 hideDesktop">
          <NavLink to="/site" onClick={props.toggle} className="nav-item">
            <span className="pro-icon-wrapper">
              <FaHome className="mr-2" />
            </span>
            <span className="siderbarSpan">Sites</span>
          </NavLink>
          <NavLink
            to="/subscription/plans"
            onClick={props.toggle}
            className="nav-item"
          >
            <span className="pro-icon-wrapper">
              <BsListCheck className="mr-2" />
            </span>
            <span className="siderbarSpan">Plans</span>
          </NavLink>
          <NavLink to="/profile" onClick={props.toggle} className="nav-item">
            <span className="pro-icon-wrapper">
              <FaUser className="mr-2" />
            </span>
            <span className="siderbarSpan">Profile</span>
          </NavLink>
          <NavLink
            to="/password/manage"
            onClick={props.toggle}
            className="nav-item"
          >
            <span className="pro-icon-wrapper">
              <FaLock className="mr-2" />
            </span>
            <span className="siderbarSpan">Security</span>
          </NavLink>
          <NavLink to="/billing" onClick={props.toggle} className="nav-item">
            <span className="pro-icon-wrapper">
              <MdPayment className="mr-2" />
            </span>
            <span className="siderbarSpan">Billing</span>
          </NavLink>
          {/* Coupons */}
          <NavLink to="/coupons" onClick={props.toggle} className="nav-item">
            <span className="pro-icon-wrapper">
              <FaTag className="mr-2" />
            </span>
            <span className="siderbarSpan">Coupons</span>
          </NavLink>

          {checkPlan.name === "Beta Plan" ? (
            <>
              <NavLink
                to="/dashboard"
                onClick={handleClick}
                className="nav-item"
              >
                <span className="pro-icon-wrapper">
                  <BsLaptop className="mr-2" />
                </span>
                <span className="siderbarSpan">Dashboard</span>
              </NavLink>
            </>
          ) : (
            <></>
          )}
        </Nav>
      </div>
    </>
  );
};

export default Sidebar;
