import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import Cookies from "js-cookie";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


/*global google*/

export default function CompanyProfileEdit(props) {
  const textinput = useRef(null);
  const history = useHistory();

  const [companyinfo, setCompanyinfo] = useState({
    uuid: "",
    name: "",
    email: "",
    phone_no: "",
    website: "",
    address: { address_1: "", city: "", country: "", postal_code: "" },
  });

  const [companyuuid, setCompanyuuid] = useState();
  const [address_1, setStreetaddress] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [postal_code, setPostalcode] = useState();

  useEffect(() => {
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get("https://auth.api.elitecloud.co.nz/company/", config)
      .then((res) => {
        console.log("Details", res.data.results[0].uuid);
        let Details = res.data.results[0];

        console.log("Details", res.data.results[0].address);
        setCompanyinfo(Details);
        setCompanyuuid(res.data.results[0].uuid);
      })
      .catch((error) => {
        //console.log("error", error);
      });
  }, []);

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace();
    console.log("addressObject", addressObject);
    let address = addressObject.address_components;
    console.log("address", address);
    let address_1 = `${address[0].long_name} ${address[1].long_name}`;
    let city = `${address[4].long_name}`;
    let country = `${address[5].short_name}`;
    let postal_code = `${address[6].long_name}`;
    console.log(address_1);
    console.log(city);
    console.log(country);
    console.log(postal_code);

    setStreetaddress(address_1);
    setCity(city);
    setCountry(country);
    setPostalcode(postal_code);
  };

  const autocomplete = new google.maps.places.Autocomplete(
    document.getElementById("autocomplete"),
    {}
  );
  autocomplete.addListener("place_changed", handlePlaceSelect);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCompanyinfo({ ...companyinfo, [name]: value });
  };

  const companySubmit = (event) => {
    event.preventDefault();
    console.log("website", companyinfo.website);
    console.log("submit_adrees_1", address_1);
    console.log("submit_city", city);
    const data = {
      name: companyinfo.name,
      email: companyinfo.email,
      phone_no: companyinfo.phone_no,
      website: companyinfo.website,
      address_1: address_1,
      city: city,
      country: country,
      postal_code: postal_code,
    };
    console.log("data", data);
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log("formBody", formBody);
    const token = Cookies.get("access_token");
    console.log("token", token);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("config", config);
    axios
      .post("https://auth.api.elitecloud.co.nz/company/new/", formBody, config)
      .then((res) => {
        console.log("companyDetails", res);
        window.location.reload();
        
      })
      .catch((error) => {
        if (error.response.data.status_code === 400) {
          toast.error("Check your parameter");
        }
      });
  };

  const deleteCompany = (event) => {
    //let company_uuid=localStorage.getItem('uuid')
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        "https://auth.api.elitecloud.co.nz/company/" + companyinfo.uuid,
        config
      )
      .then((res) => window.location.reload());
  };
  const updateCompany = (event) => {
    event.preventDefault();


    if (address_1) {
      var data = {
        name: companyinfo.name, //needed to remove company info
        email: companyinfo.email,
        phone_no: companyinfo.phone_no,
        website: companyinfo.website,
        address_1: address_1,
        city: city,
        country: country,
        postal_code: postal_code,
      };
      console.log("data", data);
      var formBody = [];
      for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log("formBody", formBody);
      const token = Cookies.get("access_token");
      //console.log("token", token);
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("config", config);
      axios
        .patch(
          "https://auth.api.elitecloud.co.nz/company/" + companyinfo.uuid,
          formBody,
          config
        )
        .then((res) => {
          toast.info("Updated")
        history.push("/profile");
        })
        .catch((error) => {
          console.log("erroronsubmit", error);
        });
    } else {
      var data1 = {
        name: companyinfo.name,
        email: companyinfo.email,
        phone_no: companyinfo.phone_no,
        website: companyinfo.website,
        address_1: companyinfo.address.address_1,
        city: companyinfo.address.city,
        country: companyinfo.address.country,
        postal_code: companyinfo.address.postal_code,
      };
      console.log("data1", data1);
      var formBody1 = [];
      for (var property1 in data1) {
        var encodedKey1 = encodeURIComponent(property1);
        var encodedValue1 = encodeURIComponent(data1[property1]);
        formBody1.push(encodedKey1 + "=" + encodedValue1);
      }
      formBody = formBody1.join("&");
      console.log("formBody", formBody1);
      const token = Cookies.get("access_token");
      console.log("token", token);
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("config", config);
      axios
        .patch(
          "https://auth.api.elitecloud.co.nz/company/" + companyinfo.uuid,
          formBody,
          config
        )
      .then((res) => {
        
          window.location.reload();
          history.push("/profile");
          toast.info("Updated")
        })
        .catch((error) => {
          console.log("erroronsubmit", error);
        });
    }
  };
  const clearAddress=()=>{
    window.location.reload();
  }
  return (
    <>
     <h2 style={{ display: "none" }}>{companyuuid}</h2>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
    <form onSubmit={companySubmit}>
        <Row>
          <Col xs={12} md={8} className="profylDetail">
            <Row>
              <Col>
                <Card.Title className="profylTitle">Street Address</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    id="autocomplete"
                    className="input-field"
                    name="address_1"
                    ref={textinput}
                    value={address_1}
                    placeholder={companyinfo.address.address_1}
                    onChange={handleChange}
                    type="text"
                  /><span style={{float:'right',color:'#ccc',cursor:'pointer'}} onClick={clearAddress}>Clear Address</span>
                </Card.Text>
              </Col>
            </Row>
            <br />

            <Row>
              <Col>
                <Card.Title className="profylTitle">City</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="city"
                    value={city}
                    placeholder={companyinfo.address.city}
                    onChange={handleChange}
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Country</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder={companyinfo.address.country}
                    value={country}
                    onChange={handleChange}
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Postal Code</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="postal_code"
                    value={postal_code}
                    placeholder={companyinfo.address.postal_code}
                    onChange={handleChange}
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Company Name</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="name"
                    value={companyinfo.name}
                    placeholder={companyinfo.name}
                    onChange={handleChange}
                    required
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Email</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="email"
                    value={companyinfo.email}
                    placeholder={companyinfo.email}
                    onChange={handleChange}
                    required
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Phone Number</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="phone_no"
                    value={companyinfo.phone_no}
                    placeholder={companyinfo.phone_no}
                    onChange={handleChange}
                    required
                  />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title className="profylTitle">Website</Card.Title>
              </Col>
              <Col>
                <Card.Text>
                  <Form.Control
                    type="text"
                    name="website"
                    value={companyinfo.website}
                    placeholder={companyinfo.website}
                    onChange={handleChange}
                    required

                  />
                </Card.Text>
              </Col>
            </Row>
            <br />

            <Row className="saveBtn">
              <Button className="btn" variant="outline-primary" type="submit">
                Create &nbsp;&nbsp;&nbsp;
              </Button>
              <Button
                className="btn"
                variant="outline-success"
                onClick={updateCompany}
              >
                Update
              </Button>
              <Button
                className="btn"
                variant="outline-danger"
                onClick={deleteCompany}
              >
                Delete
              </Button>
            </Row>
          </Col>
        </Row>
      </form>

             
     
    </>
  );
}
