import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../Img/Logo.png";

export default class ThanksRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div
                data-aos="zoom-in"
                className="registerSec col-12 aos-init aos-animate"
              >
                <img className="logo" src={logo} alt="logo" />
                <br />
                <br />
                <div className="WelcomeNote">
                  <h3>Thank you for registering with us!</h3>
                  <br />
                  <p>
                    A verification link has been sent to your email account.
                    <br />
                    Please access the link to activate the account.
                    <br />
                  </p>
                </div>
                <hr />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
