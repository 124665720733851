import React from "react";
// import { Route, Redirect } from 'react-router'
import { Container, Card, Button,Row,Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie'
const BillingHistory = () => {
  const checkUser=()=>{
    
    const token = Cookies.get('access_token');
    console.log("token",token)
      const config={
          headers:{
           'Authorization': `Bearer ${token}`
            }};
    axios.get('https://panel.api.elitecloud.co.nz/subscription/',config).then(res =>
    {console.log("res",res.data.count)}).catch(error=>{console.log("error",error)})

}
  return (
    <>
      <Container>
      <Row>
    <Col xs={12}md={10}>
    <Card className="billingCard">
          <Card.Body>
            <h3>Billing Information</h3>
            <p>View invoices & billing history, manage payment info & billing contacts for your subscriptions </p>
            <Button className="btn btn-primary" onClick={checkUser}>Go to Billing</Button>
          </Card.Body>
        </Card>
    </Col>
  </Row>
      
      </Container>
    </>
  );
};
export default BillingHistory;
