import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Navbar,
  Container,
  Row,
  Col,
  Form,
  NavDropdown,
  Modal,
  Button,
} from "react-bootstrap";
import UserImg from "./user.jpg";
import { faAlignLeft, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

const AccountNav = (props) => {
  const [smShow, setSmShow] = useState(false);
  const navDropdownNotificationTitle = (
    <>
      {props.userImg ? (
        <img
          src={props.userImg}
          alt="userimg"
          style={{ width: "20px", borderRadius: "20px" }}
        />
      ) : (
        <img src={UserImg} alt="userimg" style={{ width: "20px" }} />
      )}
    </>
  );

  const Logout = () => {
    Cookies.remove("access_token", { path: "/", domain: ".elitecloud.co.nz" });
    Cookies.remove("refresh_token", { path: "/", domain: ".elitecloud.co.nz" });
    Cookies.remove("exp_time", { path: "/", domain: ".elitecloud.co.nz" });
    window.location.href = "/login";
    return false;
  };
  return (
    <>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Are you sure you want to log out?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="outline-danger"
            type="submit"
            onClick={() => setSmShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="outline-primary"
            onClick={Logout}
            style={{ float: "right" }}
          >
            Log out
          </Button>
        </Modal.Body>
      </Modal>

      <Navbar
        bg="light"
        expand="lg"
        className="navbar shadow-sm p-3 mb-5 bg-white rounded"
      >
        <Container fluid>
          <Navbar.Brand href="#">
            <Row>
              <Col xs={2} md={2} lg={2} xl={2} xxl={2}>
                <span className="sidebartoggle">
                  <FontAwesomeIcon
                    icon={faAlignLeft}
                    onClick={props.toggle}
                    style={{ fontSize: "18px", color: "#4980be" }}
                  />
                </span>
                &nbsp;&nbsp; &nbsp;
              </Col>
              &nbsp; &nbsp;
            </Row>
          </Navbar.Brand>

          {/* <Navbar.Toggle
          aria-controls="navbarScroll"
          className="onlyonDesktoptoggle"
        /> */}
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Navbar.Text
                style={{
                  marginLeft: "50px",
                  marginTop: "3px",
                  fontSize: "14px",
                }}
              >
                Welcome {props.profile.first_name}!
              </Navbar.Text>
              <Form className="d-flex justify-content-end">
                {/* <NavDropdown
             
              id="basic-nav-dropdown2"
            >
             
              <NavDropdown.Item href="#action/3.3">
                <Row>
                  <Col xs={1} onClick={() => setSmShow(true)}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      style={{ color: "rgba(73,80,87,.5019607843137255)" }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{ color: "#333", fontWeight: "500",fontSize:'12px'  }}>
                    Sign Out
                    </b>
                  </Col>
                </Row>
              </NavDropdown.Item>
            </NavDropdown> */}
              </Form>
            </Navbar.Text>
          </Navbar.Collapse>
          <Form className="d-flex justify-content-end">
            <NavDropdown
              title={navDropdownNotificationTitle}
              id="basic-nav-dropdown2"
            >
              <NavDropdown.Item>
                <Row>
                  <Col xs={1} onClick={() => setSmShow(true)}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      style={{ color: "rgba(73,80,87,.5019607843137255)" }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b
                      style={{
                        color: "#333",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      Sign Out
                    </b>
                  </Col>
                </Row>
              </NavDropdown.Item>
            </NavDropdown>
          </Form>
        </Container>
      </Navbar>
    </>
  );
};

export default AccountNav;
