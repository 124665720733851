import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import Switch from "react-switch";

import {
  Row,
  Col,
  Table,
  NavDropdown,
  Modal,
  Button,
  Card,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/pro-light-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";

const Usersite = () => {
  const [sites, setSites] = useState([]); //site is userown site
  const [siteshows, setSiteshows] = useState([]); //siteshow is usersubscription
  const [subscriptionuuid, setSubscriptionuuid] = useState();
  const [sitenumber, setSitenumber] = useState(0);
  const [sitecount, setSitecount] = useState(0);
  const [sitelistcount, setSitelistcount] = useState(0);
  const [noicemodal, setNoticemodal] = useState(false);
  const [warningmodal, setWarningmodal] = useState(0);
  const handleClosewarning = () => setWarningmodal(false);
  const handleClosenotice = () => setNoticemodal(false);
  const [enablemodal, setEnablemodal] = useState(false);
  const handleCloseenable = () => setEnablemodal(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)
      .then((response) => {
        console.log("subcription-subresponse", response.data);
        setSiteshows(response.data.results);
        setSubscriptionuuid(response.data.results[0].uuid);
        setSitenumber(response.data.results[0].plan.maximum_sites);
        setSitecount(response.data.count);
      })
      .catch((error) => {});
    getSitelist();
  }, []);

  const getSitelist = () => {
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://panel.api.elitecloud.co.nz/site/own/", config)
      .then((response) => {
        // console.log("getSitelist", response.data);
        setSites(response.data.results);
        setSitelistcount(response.data.count);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const removeSite = (e, site, siteshow) => {
    let token = Cookies.get("access_token");
    //console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    //console.log("config", config);
    axios
      .delete(
        `https://panel.api.elitecloud.co.nz/subscription/${subscriptionuuid}/site/${site.uuid}/`,
        config
      )
      .then((response) => {
        //console.log("postresponse", response);
        if (response.data.status_code === 200) {
          getSitelist();

          toast.error("Site removed from subscription");
          handleClosewarning();
        }
      })
      .catch((error) => {
        if (error.response.data.status_code === 422) {
          toast.error("Subscription is full");
        }
        if (error.response.data.status_code === 410) {
          toast.error("site already has subscription");
        }
      });
  };

  const handleChange = (nextChecked, site, event) => {
    // console.log("nextchecked", nextChecked);
    if (nextChecked) {
      setWarningmodal(site.uuid + "site");
      // console.log("subscriptionuuid", subscriptionuuid);

      let token = Cookies.get("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .put(
          `https://panel.api.elitecloud.co.nz/subscription/${subscriptionuuid}/site/${site.uuid}/`,
          {},
          config
        )
        .then((response) => {
          // console.log("postresponse", response);
          if (response.data.status_code === 200) {
            toast.info("Site Added to the Subscription");

            setEnablemodal(`modal${site.uuid}`);
            getSitelist();
          }
        })
        .catch((error) => {
          if (error.response.data.status_code === 422) {
            setNoticemodal(true);
            getSitelist();
          }
          if (error.response.data.status_code === 410) {
            toast.error("site already has subscription");
            getSitelist();
          }
          if (error.response.data.status_code === 403) {
            toast.error("User does not own the site");
            getSitelist();
          }
        });

      getSitelist();
    } else {
      setWarningmodal(`modal${site.uuid}`);
      setChecked(nextChecked);
    }
  };

  const copyText = (e, site) => {
    console.log("e", e);
    console.log("site", site);
    if (
      navigator.clipboard.writeText(
        site.panel.serial_no + "\n" + site.panel.mac_address
      )
    ) {
      console.log("copied");
      toast.info("Copied");
    }
  };

  return (
    <>
      <span>{checked}</span>

      <Row>
        <Col xs={12} md={11}>
          <div className="ProfileAccount">
            <h2>Sites connected to my account</h2>
          </div>
        </Col>
      </Row>

      <br />

      <Row>
        <Col xs={12} md={11}>
          <Card className="siteCard">
            <Card.Body>
              <Row>
                <Col xs={4} md={1} className="p-2">
                  {" "}
                  <FontAwesomeIcon icon={faHouseChimney} />
                </Col>
                <Col xs={6} md={4} className="p-2">
                
                  {sitecount === 0 ? (
                    <>
                    Currently no plan is available. Select plans to upgrade.
                    </>
                  ) : (
                    <>
                      {sitenumber === 0 ? (
                        <>You got Infinite sites </>
                      ) : (
                        <>
                          Sites supported by your current plan is &nbsp;
                          {sitenumber}
                        </>
                      )}
                    </>
                  )}
                </Col>
                <br />
                <Col xs={12} md={2} className="p-2 mobileupdate">
                  <Link to="/subscription/plans">
                    <Button
                      variant="primary"
                      style={{ backgroundColor: "#44980be ", color: "#fff" }}
                    >
                      Upgrade Plan
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />

          {sitelistcount === 0 ? (
            <></>
          ) : (
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>App</th>
                  <th></th>
                  <th>Site</th>
                  <th>Device ID</th>
                </tr>
              </thead>
              <tbody>
                {sites.map((site, index) => (
                  <tr key={index}>
                    {/* <td>{site.uuid}</td> */}
                    {site.is_owner === true ? (
                      <td>
                        {siteshows.map((siteshow, index) => (
                          <NavDropdown.Item key={index}>
                            {site.subscription ? (
                              <>
                                {site.subscription.uuid === subscriptionuuid ? (
                                  <>
                                    <Switch
                                      onColor="#4980be"
                                      onHandleColor="#fff"
                                      handleDiameter={30}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      className="react-switch"
                                      id="material-switch"
                                      
                                      onChange={(event) =>
                                        handleChange(event, site)
                                       
                                      }
                                      checked={true}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(event) =>
                                        handleChange(event, site)
                                      }
                                      checked={checked}
                                      onColor="#4980be"
                                      onHandleColor="#4980be"
                                      handleDiameter={30}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      className="react-switch"
                                      id="material-switch"
                                    />
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* {two app slider popping up} */}
                                <Switch
                                  onChange={(event) =>
                                    handleChange(event, site)
                                  }
                                  checked={checked}
                                  onColor="#4980be"
                                  onHandleColor="#4980be"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </>
                            )}
                          </NavDropdown.Item>
                        ))}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      {site.profile_img_url ? (
                        <>
                          <div className="icon-container">
                            <img
                              src={site.profile_img_url}
                              alt="site"
                              width="50px"
                              style={{ borderRadius: "30px" }}
                            />
                            {site.panel.is_connection_healthy === true ? (
                              <div className="status-circleOnline"></div>
                            ) : (
                              <div className="status-circleOffline"></div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="icon-container">
                            <span className="site-icon-wrapper">
                              <FontAwesomeIcon
                                icon={faHouseChimney}
                                style={{ fontSize: "35px", color: "#645e5e" }}
                              />
                            </span>{" "}
                            {site.panel.is_connection_healthy === true ? (
                              <div className="status-circleOnline"></div>
                            ) : (
                              <div className="status-circleOffline"></div>
                            )}
                          </div>
                        </>
                      )}
                    </td>
                    <td>
                      <span>
                        <b>{site.name}</b>&nbsp;&nbsp;&nbsp;
                        <div className="statusCheck">
                          {site.is_owner === true ? (
                            <Badge variant="info">Owner</Badge>
                          ) : (
                            <>
                              {site.is_admin === true ? (
                                <Badge variant="primary">Admin</Badge>
                              ) : (
                                <>
                                  <Badge variant="secondary">
                                    &nbsp;&nbsp;User&nbsp;&nbsp;
                                  </Badge>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <br />
                        {site.address.address_line_1},
                        {site.address.address_line_2}
                        {site.address.city},{site.address.country}
                      </span>
                    </td>

                    <td
                      style={{ paddingTop: "25px" }}
                      id="copyTip"
                      onClick={(e) => {
                        copyText(e, site);
                      }}
                    >
                      {site.panel.mac_address} &nbsp;&nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faCopy} />
                      <br />({site.panel.serial_no})
                    </td>
                    <td>
                      <div className="noticeModal">
                        <Modal
                          show={enablemodal === `modal${site.uuid}`}
                          dialogClassName="modal-90w"
                          aria-labelledby="example-custom-modal-styling-title"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: "12px" }}>
                              <span
                                style={{
                                  color: "#333",
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                              >
                                Congratulations!
                              </span>
                              <br />
                              <br />
                              <br />
                              "This site now has full app functionality"
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Button
                              variant="outline-secondary"
                              onClick={handleCloseenable}
                              style={{ marginLeft: "42%" }}
                            >
                              OK
                            </Button>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="noticeModal">
                        <Modal
                          show={warningmodal === `modal${site.uuid}`}
                          dialogClassName="modal-90w"
                          aria-labelledby="example-custom-modal-styling-title"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: "12px" }}>
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  fontWeight: "bolder",
                                }}
                              >
                                Warning
                              </span>
                              <br />
                              <br />
                              <br />
                              'Turn Off' will remove this site from your
                              subscribed plan & all paid features including
                              'Push Notifications' will become inactive.
                              <br />
                              <br />
                              Paid features also include 'Control' & 'Sensors'.
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Button
                              variant="outline-secondary"
                              onClick={handleClosewarning}
                            >
                              Back
                            </Button>
                            <Button
                              variant="primary"
                              onClick={(e) => {
                                removeSite(e, site);
                              }}
                              style={{ float: "right" }}
                            >
                              Turn Off
                            </Button>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <div></div>
      {/* notice modal */}
      <>
        <div className="noticeModal">
          <Modal
            show={noicemodal}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "12px" }}>
                <span
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  Notice
                </span>
                <br />
                <br />
                <br />
                Your current plan only supports full app functionality on 1
                site.
                <br />
                <br />
                Please Upgrade your plan to unlock all app features for this
                site.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button variant="outline-secondary" onClick={handleClosenotice}>
                Back
              </Button>
              <Link to="/subscription/plans">
                <Button
                  variant="primary"
                  onClick={handleClosenotice}
                  style={{ float: "right" }}
                >
                  Upgrade Plan
                </Button>
              </Link>
            </Modal.Body>
          </Modal>
        </div>
      </>

      {/* notice modal ends here */}
      {/* warning modal */}
      <></>

      {/* warning modal ends here */}
    </>
  );
};

export default Usersite;
