import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
const BillingInfo = () => {
  const getCustomer = () => {
    const token = Cookies.get("access_token");
    console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <Row>
        <div className="ProfileAccount">
          <h2>Billing Information</h2>
        </div>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <Card className="billingCard">
            <Card.Body>
              <p>
                View invoices & billing history, manage payment info & billing
                contacts for your subscriptions{" "}
              </p>
              <Button className="btn btn-primary" onClick={getCustomer}>
                Go to Billing
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default BillingInfo;
