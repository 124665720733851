import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./App.css";
import Register from "./Account/Register";
import ThanksRegister from "./Account/Thankyou";
import Login from "./Account/Login";
import ResetPassword from "./Account/ResetPassword";
import ConfirmPassword from "./Account/ConfirmPassword";
import ProtectedRoute from "./Account/ProtectedRoute";
import TermsCondition from "./Account/TermsCondition";
import Layout from "./Profile/Layout";
import Unauthorized from "./Account/Unauthorized";
import Success from "./Account/Success";
import Cancel from "./Account/Cancel";
import { AuthProvider } from "./Context/AuthContext";
import Privacy from "./Account/Privacy";
import CookiePolicy from "./Account/Cookiepolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path={"/"} component={Login} />
            <Route exact path={"/register"} component={Register} />
            <Route exact path={"/thankyou"} component={ThanksRegister} />
            <Route exact path={"/login"} component={Login} />
            <Route exact path={"/resetpassword"} component={ResetPassword} />
            <Route exact path={"/reset"} component={ConfirmPassword} />
            <Route exact path={"/terms"} component={TermsCondition} />
            <Route exact path={"/privacy"} component={Privacy} />
            <Route exact path={"/cookiepolicy"} component={CookiePolicy} />
            <Route exact path={"/unauthorized"} component={Unauthorized} />
            <Route exact path={"/success"} component={Success} />
            <Route exact path={"/cancel"} component={Cancel} />
            <ProtectedRoute exact component={Layout} />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
