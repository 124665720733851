import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RedeemCoupon = ({ subId }) => {
  const notify = (message) => toast.error(message);
  const succcess = (message) => toast.info(message);
  const [isSuccess, setSuccess] = useState(false);

  const redeemCode = () => {
    var couponCode = document.getElementById("coupon").value;

    let token = Cookies.get("access_token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = { code: couponCode };

    console.log("Sub ID", subId);

    axios
      .put(
        `https://panel.api.elitecloud.co.nz/subscription/${subId}/promotion_code/`,
        body,
        headers
      )
      .then((response) => {
        succcess("Success");
        setSuccess(true);
      })

      .catch((error) => {
        notify("Failed to Redeem Coupon");
        console.log("Error: ", error);
      });
  };

  const redirectBilling = () => {
    const token = Cookies.get("access_token");
    console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <>
        <ToastContainer />
        <div>
          {isSuccess ? (
            <>
              <h5 style={{ textAlign: "center" }}>
                Your coupon will be applied for the next payment period!
              </h5>
              <Button
                variant="primary"
                style={{ marginTop: "15px" }}
                onClick={redirectBilling}
              >
                Go to Billing
              </Button>
            </>
          ) : (
            <Form>
              <Form.Group>
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control type="text" id="coupon" />
              </Form.Group>
              <Button
                variant="primary"
                style={{ marginTop: "15px" }}
                onClick={redeemCode}
              >
                Redeem
              </Button>
            </Form>
          )}
        </div>
      </>
    </div>
  );
};

export default RedeemCoupon;
