import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import axios from "axios";
import {
  Col,
  Row,
  Container,
  Card,
  ListGroup,
  Button,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faHouseBuilding,
  faUserHelmetSafety,
  faBuilding,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import YearSubscription from "./YearSubscription";
// import { faWindows } from "@fortawesome/free-brands-svg-icons";
const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};
export default function Subscription(props) {
  const [subscriptionuuid, setSubscriptionuuid] = useState("");
  const [setPlans] = useState([]);
  const betauuid = `39ad070e-d73d-48d0-b998-6b884914c545`;
  const homeuuid = `82cfca7d-ac97-4108-903e-33646dbccff5`;
  const multiuuid = `27f2692f-dc56-4614-b937-0b9d4dd58472`;
  const enterpriseuuid = `4c255076-9b0a-45d8-8a87-c316cd50031a`;
  const installeruuid = `464103ac-3be4-49dc-8c6e-3d0787f96e14`;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [basemodal, setBasemodal] = useState(false);
  const [multimodal, setMultimodal] = useState(false);
  const [enterprisemodal, setEnterprisemodal] = useState(false);
  const [installermodal, setInstallermodal] = useState(false);
  const [showUpgradeSubscriptionModal, setShowUpgradeSubscriptionModal] =
    useState(false);
  const [redirectingToBilling, setRedirectingToBilling] = useState(false);
  const handleOpenbasemodal = () => setBasemodal(true);
  const handleClosebasemodal = () => setBasemodal(false);
  const handleOpenmultimodal = () => setMultimodal(true);
  const handleClosemultimodal = () => setMultimodal(false);
  const handleOpenenterprisemodal = () => setEnterprisemodal(true);
  const handleCloseenterprisemodal = () => setEnterprisemodal(false);
  const handleOpeninstallermodal = () => setInstallermodal(true);
  const handleCloseinstallermodal = () => setInstallermodal(false);
  let currentplancolor = "green";
  let otherplancolor = "#4980be";

  const [isHomePlan, setHomePlan] = useState(false);

  const userSubscription = () => {
    let token = Cookies.get("access_token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get("https://panel.api.elitecloud.co.nz/subscription/", config)

      .then((response) => {
        console.log("userSubscription", response.data.results);
        if (response.data.count !== 0) {
          setSubscriptionuuid(response.data.results[0].plan.uuid);
          if (response.data.results[0].plan.uuid === betauuid) {
            setShow(true);
            localStorage.setItem("pop_status", 1);
          }
        } else {
          console.log("count is 0");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    try {
      userSubscription();

      let token = Cookies.get("access_token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      //user subscription
      axios
        .get("https://panel.api.elitecloud.co.nz/subscription/", config)

        .then((response) => {
          console.log("subcriptionList", response);
          if (response.data.count !== 0) {
            setSubscriptionuuid(response.data.results[0].plan.uuid);

            if (response.data.results[0].plan.name.includes("Home")) {
              setHomePlan(true);
            }
            // if (response.data.results[0].plan.uuid === betauuid) {
            //   setShow(true);
            // }
          } else {
            console.log("count is 0");
          }
        })
        .catch(
          (error) => {
            console.log("error", error);
          },
          [token]
        );

      //plans
      axios
        .get("https://panel.api.elitecloud.co.nz/subscription/plan/", config)
        .then((response) => {
          console.log("response-plans", response.data.results);
          setPlans(response.data.results);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (ex) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlans]);

  const redirectToHomeCheckout = async () => {
    let token = Cookies.get("access_token");
    //console.log("token", token);
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/82cfca7d-ac97-4108-903e-33646dbccff5/update/",
          headers
        )
        .then((response) => {
          setBasemodal(false);

          toast.info("Subscription is Updated");
          window.location.reload();
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/82cfca7d-ac97-4108-903e-33646dbccff5/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    }
  };

  const redirectToMultiCheckout = async () => {
    let token = Cookies.get("access_token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/27f2692f-dc56-4614-b937-0b9d4dd58472/update/",
          headers
        )
        .then((response) => {
          setMultimodal(false);
          toast.info("Subscription is Updated");
          window.location.reload();
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/27f2692f-dc56-4614-b937-0b9d4dd58472/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          otherplancolor = "#4980be";
        });
    }
  };
  const redirectToEnterpriseCheckout = async () => {
    let token = Cookies.get("access_token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/4c255076-9b0a-45d8-8a87-c316cd50031a/update/",
          headers
        )
        .then((response) => {
          setEnterprisemodal(false);
          toast.info("Subscription is Updated");
          window.location.reload();
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/4c255076-9b0a-45d8-8a87-c316cd50031a/subscribe/",
          headers
        )
        .then((response) => {
          //console.log("response",response)
          window.location.href = response.data.payload.url;
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
        });
    }
  };
  const redirectToInstallerCheckout = async () => {
    let token = Cookies.get("access_token");
    // console.log("token", token);
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const response = await axios.get(
    //   "https://panel.api.elitecloud.co.nz/subscription/plan/4c255076-9b0a-45d8-8a87-c316cd50031a/subscribe/",
    //   headers
    // );
    // window.location.href = response.data.payload.url;
    // console.log("response", response.data.payload.url);
    if (subscriptionuuid) {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/464103ac-3be4-49dc-8c6e-3d0787f96e14/update/",
          headers
        )
        .then((response) => {
          setInstallermodal(false);
          toast.info("Subscription is Updated");
          window.location.reload();
          currentplancolor = "green";
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
          if (error.response.data.status_code === 422) {
            toast.error("Subscription is unavailable.");
          }
        });
    } else {
      axios
        .get(
          "https://panel.api.elitecloud.co.nz/subscription/plan/464103ac-3be4-49dc-8c6e-3d0787f96e14/subscribe/",
          headers
        )
        .then((response) => {
          window.location.href = response.data.payload.url;
          // console.log("response", response.data.payload.url);
        })
        .catch((error) => {
          if (error.response.data.status_code === 406) {
            toast.error("User cannot have more than one subscription");
          }
        });
    }
  };

  const redirectToBillingPage = () => {
    const token = Cookies.get("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setRedirectingToBilling(true);
    axios
      .get("https://auth.api.elitecloud.co.nz/billing/portal/", config)
      .then((res) => {
        window.location.href = res.data.payload.url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="noticeModal">
        <Modal
          show={show}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Hello Beta Tester!
              </span>
              <br />
              <br />
              Your are part of our Beta test program.If you would like to
              migrate your account to our subscription service, please contact
              &nbsp;
              <ButtonMailto
                label="support@aap.co.nz"
                mailto="mailto:support@aap.co.nz"
              />
              <br />
              <br />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ float: "right" }}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
      </div>

      <Row>
        <div className="ProfileAccount">
          <h2>Plans</h2>
        </div>
      </Row>

      <Row className="justify-content-center subscriptionsContainer">
        <Col xs={12} md={12} lg={12} className="SubscriptionTabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Container className="m-auto ">
                <Row className="justify-content-center">
                  <Col md="auto">
                    <Nav variant="pills">
                      <Nav.Item className="flex-item">
                        <Nav.Link eventKey="first" href="#">
                          Annually
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="flex-item">
                        <Nav.Link eventKey="second" href="#">
                          Monthly
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Container>

              <br />
              <br />
              <br />
              <br />

              <Tab.Content>
                <Tab.Pane eventKey="second">
                  <Col xs={12} md={12} lg={11}>
                    <div className="SubscribeTabs">
                      <Row className="SubscribeSec">
                        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                          <Card className="text-center">
                            <Card.Header>
                              <h4 className="SubscribeIcon">
                                <FontAwesomeIcon icon={faHouse} />
                              </h4>
                              <p>
                                <b>Home Plan</b>
                              </p>
                              <p>
                                Sign up to home with 1 site & up to 5 users per
                                site
                              </p>
                              <h3>
                                $1.49&nbsp;
                                <small className="text-muted">per month</small>
                              </h3>
                            </Card.Header>
                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;1 Site
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;5 Users
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Arm & disarm
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Output control
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Real time zone status
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Sensor snooze function
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;History
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Push notifications
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                            {!subscriptionuuid ? (
                              <>
                                <Card.Footer
                                  className="text-muted"
                                  onClick={redirectToHomeCheckout}
                                >
                                  Basic Plan
                                </Card.Footer>
                              </>
                            ) : (
                              <>
                                {subscriptionuuid === betauuid ? (
                                  <>
                                    <Card.Footer className="text-mutedBeta">
                                      Basic Plan
                                    </Card.Footer>
                                  </>
                                ) : (
                                  <>
                                    {homeuuid === subscriptionuuid ? (
                                      <>
                                        <Card.Footer
                                          className="text-mutedCurrent"
                                          style={{
                                            backgroundColor: currentplancolor,
                                          }}
                                          onClick={handleOpenbasemodal}
                                        >
                                          Current Plan
                                        </Card.Footer>
                                      </>
                                    ) : (
                                      <>
                                        <Card.Footer
                                          className="text-muted"
                                          style={{
                                            backgroundColor: otherplancolor,
                                          }}
                                          onClick={() =>
                                            setShowUpgradeSubscriptionModal(
                                              true
                                            )
                                          }
                                        >
                                          Basic Plan
                                        </Card.Footer>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3}>
                          <Card className="text-center">
                            <Card.Header>
                              <h4 className="SubscribeIcon">
                                <FontAwesomeIcon icon={faHouseBuilding} />
                              </h4>
                              <p>
                                <b>Multi Plan</b>
                              </p>
                              <p>
                                Sign up to multi with 4 sites & up to 10 users
                                per site
                              </p>
                              <h3>
                                $2.49&nbsp;
                                <small className="text-muted">per month</small>
                              </h3>
                            </Card.Header>

                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;4 Sites
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;10 Users
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Arm & disarm
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Output control
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Real time zone status
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Sensor snooze function
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;History
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Push notifications
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>

                            {!subscriptionuuid ? (
                              <>
                                {" "}
                                <Card.Footer
                                  className="text-muted"
                                  onClick={redirectToMultiCheckout}
                                >
                                  Multi Plan
                                </Card.Footer>
                              </>
                            ) : (
                              <>
                                {" "}
                                {subscriptionuuid === betauuid ? (
                                  <>
                                    <Card.Footer className="text-mutedBeta">
                                      Multi Plan
                                    </Card.Footer>
                                  </>
                                ) : (
                                  <>
                                    {multiuuid === subscriptionuuid ? (
                                      <>
                                        <Card.Footer
                                          className="text-mutedCurrent"
                                          style={{
                                            backgroundColor: currentplancolor,
                                          }}
                                          onClick={handleOpenmultimodal}
                                        >
                                          Current Plan
                                        </Card.Footer>
                                      </>
                                    ) : (
                                      <>
                                        <Card.Footer
                                          style={{
                                            backgroundColor: otherplancolor,
                                          }}
                                          className="text-muted"
                                          onClick={() =>
                                            setShowUpgradeSubscriptionModal(
                                              true
                                            )
                                          }
                                        >
                                          Upgrade to Multi
                                        </Card.Footer>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3}>
                          <Card className="text-center">
                            <Card.Header>
                              <h4 className="SubscribeIcon">
                                <FontAwesomeIcon icon={faBuilding} />
                              </h4>
                              <p>
                                <b>Enterprise Plan</b>
                              </p>
                              <p>
                                Sign up to enterprise with 10 sites, up to 100
                                users per site & management dashboard
                              </p>
                              <h3>
                                $10&nbsp;
                                <small className="text-muted">per month</small>
                              </h3>
                            </Card.Header>

                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;10 Sites & up to 100
                                        Users
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;User management
                                        dashboard
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Arm & disarm
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Output control
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Real time zone status
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Sensor snooze function
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;History
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Push notifications
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                            {!subscriptionuuid ? (
                              <>
                                <Card.Footer
                                  className="text-muted"
                                  onClick={redirectToEnterpriseCheckout}
                                >
                                  Enterprise Plan
                                </Card.Footer>
                              </>
                            ) : (
                              <>
                                {subscriptionuuid === betauuid ? (
                                  <>
                                    <Card.Footer className="text-mutedBeta">
                                      Enterprise Plan
                                    </Card.Footer>
                                  </>
                                ) : (
                                  <>
                                    {enterpriseuuid === subscriptionuuid ? (
                                      <>
                                        <Card.Footer
                                          disable
                                          className="text-mutedCurrent"
                                          onClick={handleOpenenterprisemodal}
                                        >
                                          Current Plan
                                        </Card.Footer>
                                      </>
                                    ) : (
                                      <>
                                        <Card.Footer
                                          className="text-muted"
                                          onClick={() =>
                                            setShowUpgradeSubscriptionModal(
                                              true
                                            )
                                          }
                                        >
                                          Upgrade to Enterprise
                                        </Card.Footer>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3}>
                          <Card className="text-center">
                            <Card.Header>
                              <h4 className="SubscribeIcon">
                                <FontAwesomeIcon icon={faUserHelmetSafety} />
                              </h4>
                              <p>
                                <b>Installer Plan</b>
                              </p>
                              <p>
                                Sign up to the installers management dashboard &
                                app plan
                              </p>
                              <h3>
                                $10&nbsp;
                                <small className="text-muted">per month</small>
                              </h3>
                            </Card.Header>

                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Over the air
                                        programming & backup
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Over the air updates
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Unlimited dashboard
                                        sites
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;4 App Sites
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;10 App Users
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Alarm Status & Control
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Comprehensive System
                                        History
                                      </ListGroup.Item>
                                      <ListGroup.Item>
                                        <FontAwesomeIcon icon={faCheck} />
                                        &nbsp;&nbsp;&nbsp;Notifications
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                            {subscriptionuuid === betauuid ? (
                              <>
                                <Card.Footer
                                  className="text-mutedBeta"
                                  style={{
                                    pointerEvents: "none",
                                    cursor: "no-drop",
                                  }}
                                >
                                  Coming Soon
                                </Card.Footer>
                              </>
                            ) : (
                              <>
                                {installeruuid === subscriptionuuid ? (
                                  <>
                                    <Card.Footer
                                      className="text-mutedCurrent"
                                      onClick={handleOpeninstallermodal}
                                    >
                                      Coming Soon
                                    </Card.Footer>
                                  </>
                                ) : (
                                  <>
                                    <Card.Footer
                                      className="text-muted"
                                      onClick={handleOpeninstallermodal}
                                      style={{
                                        pointerEvents: "none",
                                        cursor: "no-drop",
                                      }}
                                    >
                                      Coming Soon
                                    </Card.Footer>
                                  </>
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Tab.Pane>

                {/* //yearly subscription */}
                <Tab.Pane eventKey="first">
                  <YearSubscription
                    isHome={isHomePlan}
                    subscriptionuuid={subscriptionuuid}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
      <div className="noticeModal">
        <Modal
          show={basemodal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Home plan
              </span>
              <br />
              <br />
              <br />
              Press confirm to switch to <b>home plan</b> with 1 site & up to 5
              users.
              <br />
              You will be charged $1.49 per month for this service.
              <br />
              Please note that this action is non refundable.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button variant="outline-secondary" onClick={handleClosebasemodal}>
              Cancel
            </Button>
            <Link to="/subscription/plans">
              <Button
                variant="primary"
                onClick={redirectToHomeCheckout}
                style={{ float: "right" }}
              >
                Confirm
              </Button>
            </Link>
          </Modal.Body>
        </Modal>
      </div>
      <div className="noticeModal">
        <Modal
          show={multimodal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Multi plan
              </span>
              <br />
              <br />
              <br />
              Press confirm to switch to <b>Multi plan</b> with 4 site & up to
              10 users.
              <br />
              You will be charged $2.49 per month for this service.
              <br />
              Please note that this action is non refundable
              {isHomePlan ? (
                <>
                  <br />
                  and any applied coupons will be overwritten.
                </>
              ) : (
                <>.</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button variant="outline-secondary" onClick={handleClosemultimodal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={redirectToMultiCheckout}
              style={{ float: "right" }}
            >
              Confirm
            </Button>
          </Modal.Body>
        </Modal>
      </div>
      <br />

      <div className="noticeModal">
        <Modal
          show={enterprisemodal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Enterprise plan
              </span>
              <br />
              <br />
              <br />
              {/* You will be charged $10 permonth.<br/>
                Confirm switching to <b>Enterprise plan</b> with 10 site & up to 100 users?? */}
              Press confirm to switch to <b>Enterprise plan</b> with 10 site &
              up to 100 users.
              <br />
              You will be charged $10 per month for this service.
              <br />
              Please note that this action is non refundable
              {isHomePlan ? (
                <>
                  <br />
                  and any applied coupons will be overwritten.
                </>
              ) : (
                <>.</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="outline-secondary"
              onClick={handleCloseenterprisemodal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={redirectToEnterpriseCheckout}
              style={{ float: "right" }}
            >
              Confirm
            </Button>
          </Modal.Body>
        </Modal>
      </div>
      <br />

      <div className="noticeModal">
        <Modal
          show={installermodal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              <span
                style={{
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bolder",
                }}
              >
                Installer Plan
              </span>
              <br />
              <br />
              <br />
              {/* You will be charged $10 permonth.<br/>
                Confirm switching to <b>Installer plan</b>?? */}
              Press confirm to switch to <b>Installer plan</b> . You will be
              charged $10 per month for this service.
              <br />
              Please note that this action is non refundable.
              {isHomePlan ? (
                <>
                  <br />
                  and any applied coupons will be overwritten.
                </>
              ) : (
                <>.</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              variant="outline-secondary"
              onClick={handleCloseinstallermodal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={redirectToInstallerCheckout}
              style={{ float: "right" }}
            >
              Confirm
            </Button>
          </Modal.Body>
        </Modal>
      </div>

      <div className="noticeModal">
        <Modal
          show={showUpgradeSubscriptionModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "12px" }}>
              Upgrade Subscription
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please cancel your current plan and subscribe to the new plan.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() => setShowUpgradeSubscriptionModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={redirectToBillingPage}
              style={{ float: "right" }}
            >
              {redirectingToBilling ? "Redirecting..." : "Cancel Plan"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
