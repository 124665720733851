import React, { useState, useEffect, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Form from "react-bootstrap/Form";
import "./TermsModal.css";

export default function TermsModal({ show, handleClose, handleUpdateTerms }) {
  const [terms, setTerms] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [cookiesPolicy, setCookiesPolicy] = useState("");
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsLatestVersion, setTermsLatestVersion] = useState(null);

  const termsToggleRef = useRef(null);
  const cookiesTogglenRef = useRef(null);
  const privacyToggleRef = useRef(null);

  useEffect(() => {
    async function loadTerms() {
      try {
        const response = await axios(
          "https://auth.api.elitecloud.co.nz/terms/latest"
        );

        if (response && response.data && response.data.payload) {
          const payload = response.data.payload;

          setTerms(payload.terms_and_conditions);
          setCookiesPolicy(payload.cookie_policy);
          setPrivacyPolicy(payload.privacy_policy);
          setTermsLatestVersion(payload.version);
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadTerms();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered scrollable size="xl">
      <ModalHeader>
        <ModalTitle>We have Updated our terms & conditions!</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              ref={termsToggleRef}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>Terms & Conditions</div>
                <Form.Check type="checkbox" readOnly checked={termsAccepted} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ReactMarkdown
                  className="markdown"
                  children={terms}
                  remarkPlugins={[remarkGfm]}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1rem",
                  }}
                >
                  <div
                    onClick={() => {
                      setTermsAccepted(!termsAccepted);
                      termsToggleRef.current.click();
                    }}
                  >
                    <Form.Check
                      className="custom-check"
                      checked={termsAccepted}
                      label="I Accept the Terms And Conditions"
                    />
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              ref={privacyToggleRef}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>Privacy Policy</div>
                <Form.Check
                  type="checkbox"
                  readOnly
                  checked={privacyAccepted}
                />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ReactMarkdown
                  className="markdown"
                  children={privacyPolicy}
                  remarkPlugins={[remarkGfm]}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1rem",
                  }}
                >
                  <div
                    onClick={() => {
                      setPrivacyAccepted(!privacyAccepted);
                      privacyToggleRef.current.click();
                    }}
                  >
                    <Form.Check
                      className="custom-check"
                      checked={privacyAccepted}
                      label="I Accept the Privacy Policy"
                    />
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="2"
              ref={cookiesTogglenRef}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>Cookie Policy</div>
                <Form.Check
                  type="checkbox"
                  readOnly
                  checked={cookiesAccepted}
                />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <ReactMarkdown
                  className="markdown"
                  children={cookiesPolicy}
                  remarkPlugins={[remarkGfm]}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1rem",
                  }}
                >
                  <div
                    onClick={() => {
                      setCookiesAccepted(!cookiesAccepted);
                      cookiesTogglenRef.current.click();
                    }}
                  >
                    <Form.Check
                      className="custom-check"
                      checked={cookiesAccepted}
                      label="I Accept the Cookie Policy"
                    />
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!termsAccepted || !cookiesAccepted || !privacyAccepted}
          onClick={() =>
            handleUpdateTerms(
              termsLatestVersion,
              termsAccepted && cookiesAccepted && privacyAccepted
            )
          }
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}
